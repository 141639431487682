import React from 'react';
import styles from './index.module.scss'
import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SwiperCore, { Pagination, } from "swiper/core";
import LinkItem from "../LinkItem/LinkItem";

import d_Photo9 from '../../media/img/DJI_0553_B.webp'
import video8Poster from '../../media/video/Natanya.jpg'
import d_Photo10 from '../../media/img/Lobby Gr_wall_01.webp'
import d_Photo11 from '../../media/img/Lobby beton_03.jpg'
import d_Photo12 from '../../media/img/5rooms.jpg'
import d_Photo13 from '../../media/img/PENTHOUSE.jpg'
import VideoPoster from '../../media/video/Ramat_Gan.jpg'
import VideoTelAvivPoster from '../../media/video/Tel_Aviv.jpg'
import d_Photo14 from '../../media/img/Layla.webp'
import d_Photo15 from '../../media/img/dira2_hazer_final.jpg'
import d_Photo16 from '../../media/img/dirat_gan1-pnim_final.jpg'
import d_Photo17 from '../../media/img/dirat_gan2-pnim_marble.jpg'
import video1Poster from '../../media/video/Bat Yam.jpg'
import d_Photo18 from '../../media/img/blue_batyam-5roomas-c01_0.jpg'
import d_Photo19 from '../../media/img/blue_batyam-br-c01_0.jpg'
import d_Photo20 from '../../media/img/blue_batyam-lobby-c02_0.jpg'
import d_Photo21 from '../../media/img/blue_batyam-gym-c01_0.jpg'
import video2Poster from '../../media/video/Ramat Hasharon.jpg'
import d_Photo23 from '../../media/img/DIRAT_TIPUSIT_C-GR_01.webp'
import d_Photo24 from '../../media/img/DIRAT_TIPUSIT_C-GR_02.webp'
import d_Photo25 from '../../media/img/PENTHAUS_B-PH_01.webp'
import d_Photo26 from '../../media/img/857_EXTERIOR_003.webp'
import video3Poster from '../../media/video/yakov.webp'
import d_Photo27 from '../../media/img/Kottege-Backview.webp'
import d_Photo28 from '../../media/img/Kotege-L1.jpg'
import d_Photo29 from '../../media/img/Kotege-Bedroom.jpg'
import video4Poster from '../../media/video/Tirat Carmel.jpg'
import video5Poster from '../../media/video/רוטשטיין - אנימציית חוץ1.jpg'
import video6Poster from '../../media/video/רוטשטיין - אנימציית מרפסת.jpg'
import video7Poster from '../../media/video/אנימציית דירת גן- רוטשטיין.jpg'
import d_Photo30 from '../../media/img/רוטשטיין טירת הכרמל לכיוון הר הכרמל חלל מגורים.jpg'



import m_Photo9 from '../../media/mob/img/DJI_0553_B.webp'
import m_Photo10 from '../../media/mob/img/Lobby Gr_wall_01.webp'
import m_Photo11 from '../../media/mob/img/Lobby beton_03.jpg'
import m_Photo12 from '../../media/mob/img/5rooms.jpg'
import m_Photo13 from '../../media/mob/img/PENTHOUSE.jpg'
import m_Photo14 from '../../media/mob/img/Layla.webp'
import m_Photo15 from '../../media/mob/img/dira2_hazer_final.jpg'
import m_Photo16 from '../../media/mob/img/dirat_gan1-pnim_final.jpg'
import m_Photo17 from '../../media/mob/img/dirat_gan2-pnim_marble.jpg'
import m_Photo18 from '../../media/mob/img/blue_batyam-5roomas-c01_0.jpg'
import m_Photo19 from '../../media/mob/img/blue_batyam-br-c01_0.jpg'
import m_Photo20 from '../../media/mob/img/blue_batyam-lobby-c02_0.jpg'
import m_Photo21 from '../../media/mob/img/blue_batyam-gym-c01_0.jpg'
import m_Photo23 from '../../media/mob/img/DIRAT_TIPUSIT_C-GR_01.webp'
import m_Photo24 from '../../media/mob/img/DIRAT_TIPUSIT_C-GR_02.webp'
import m_Photo25 from '../../media/mob/img/PENTHAUS_B-PH_01.webp'
import m_Photo26 from '../../media/mob/img/857_EXTERIOR_003.webp'
import m_Photo27 from '../../media/mob/img/Kottege-Backview.webp'
import m_Photo28 from '../../media/mob/img/Kotege-L1.jpg'
import m_Photo29 from '../../media/mob/img/Kotege-Bedroom.jpg'
import m_Photo30 from '../../media/mob/img/רוטשטיין טירת הכרמל לכיוון הר הכרמל חלל מגורים.jpg'

import video8 from '../../media/video/Natanya.mp4'
import Video from '../../media/video/Ramat_Gan.mp4'
import VideoTelAviv from '../../media/video/Tel_Aviv.mp4'
import video1 from '../../media/video/Bat Yam.mp4'
import video2 from '../../media/video/Ramat Hasharon.mp4'
import video3 from '../../media/video/yakov.mp4'
import video4 from '../../media/video/Tirat Carmel.mp4'
import video5 from '../../media/video/רוטשטיין - אנימציית חוץ1.mp4'
import video6 from '../../media/video/רוטשטיין - אנימציית מרפסת.mp4'
import video7 from '../../media/video/אנימציית דירת גן- רוטשטיין.mp4'

SwiperCore.use([Pagination]);
const BlockRight = ({ activeDoorAnimation, currentTab, AllTabs, setActiveDoor, setCurrentTab }) => {

    const widthWindow = document.documentElement.clientWidth <= 768

    const Photo9 = widthWindow ? m_Photo9 : d_Photo9
    const Photo10 = widthWindow ? m_Photo10 : d_Photo10
    const Photo11 = widthWindow ? m_Photo11 : d_Photo11
    const Photo12 = widthWindow ? m_Photo12 : d_Photo12
    const Photo13 = widthWindow ? m_Photo13 : d_Photo13
    const Photo14 = widthWindow ? m_Photo14 : d_Photo14
    const Photo15 = widthWindow ? m_Photo15 : d_Photo15
    const Photo16 = widthWindow ? m_Photo16 : d_Photo16
    const Photo17 = widthWindow ? m_Photo17 : d_Photo17
    const Photo18 = widthWindow ? m_Photo18 : d_Photo18
    const Photo19 = widthWindow ? m_Photo19 : d_Photo19
    const Photo20 = widthWindow ? m_Photo20 : d_Photo20
    const Photo21 = widthWindow ? m_Photo21 : d_Photo21
    const Photo23 = widthWindow ? m_Photo23 : d_Photo23
    const Photo24 = widthWindow ? m_Photo24 : d_Photo24
    const Photo25 = widthWindow ? m_Photo25 : d_Photo25
    const Photo26 = widthWindow ? m_Photo26 : d_Photo26
    const Photo27 = widthWindow ? m_Photo27 : d_Photo27
    const Photo28 = widthWindow ? m_Photo28 : d_Photo28
    const Photo29 = widthWindow ? m_Photo29 : d_Photo29
    const Photo30 = widthWindow ? m_Photo30 : d_Photo30

    const arraPhotoSwiper1 = [
        { photo: [video8, video8Poster] },
        { photo: Photo9 },
        { photo: Photo10 },
        { photo: Photo11 }
    ]
    const arraPhotoSwiper2 = [
        { photo: [Video, VideoPoster] },
        { photo: Photo12 },
        { photo: Photo13 },

    ]

    const arraPhotoSwiper3 = [
        { photo: [VideoTelAviv, VideoTelAvivPoster] },
        { photo: Photo14 },
        { photo: Photo15 },
        { photo: Photo16 },
        { photo: Photo17 }
    ]

    const arraPhotoSwiper4 = [
        { photo: [video1, video1Poster] },
        { photo: Photo18 },
        { photo: Photo19 },
        { photo: Photo20 },
        { photo: Photo21 }
    ]

    const arraPhotoSwiper5 = [
        { photo: [video2, video2Poster] },
        { photo: Photo23 },
        { photo: Photo24 },
        { photo: Photo25 },
        { photo: Photo26 },
    ]

    const arraPhotoSwiper6 = [
        { photo: [video3, video3Poster] },
        { photo: Photo27 },
        { photo: Photo28 },
        { photo: Photo29 }
    ]

    const arraPhotoSwiper7 = [
        { photo: [video4, video4Poster] },
        { photo: [video5, video5Poster] },
        { photo: [video6, video6Poster] },
        { photo: [video7, video7Poster] },
        { photo: Photo30 },
    ]

    return (
        <>
            <div key={currentTab + 'innerDiv'}
                className={classNames(styles.blockDoorSecond, {
                    [styles.active]: activeDoorAnimation
                })}>
                <Swiper key={currentTab + 'innerDiv'} loop={!widthWindow} initialSlide={0} className={styles.containerSwiper} id="swiper"
                    pagination={{ "clickable": true }}>
                    {currentTab === 0 &&
                        arraPhotoSwiper1.map((r, i) => {
                            return (
                                i === 0 ?
                                    <SwiperSlide key={r.photo + i}>
                                        <video src={r.photo[0]} loop muted autoPlay playsInline poster={r.photo[1]}></video>
                                    </SwiperSlide>
                                    :
                                    <SwiperSlide key={r.photo + i}>
                                        <LazyLoadImage
                                            placeholderSrc='blur'
                                            alt="image"
                                            height="100%"
                                            effect="blur"
                                            src={r.photo}
                                            width="100%" />
                                    </SwiperSlide>
                            )
                        })
                    }
                    {currentTab === 1 &&
                        arraPhotoSwiper2.map((r, i) => {
                            return (
                                i === 0 ?
                                    <SwiperSlide key={r.photo + i}>
                                        <video src={r.photo[0]} loop muted autoPlay playsInline poster={r.photo[1]}></video>
                                    </SwiperSlide>
                                    :
                                    <SwiperSlide key={r.photo + i}>
                                        <LazyLoadImage
                                            placeholderSrc='blur'
                                            alt="image"
                                            height="100%"
                                            effect="blur"
                                            src={r.photo}
                                            width="100%" />
                                    </SwiperSlide>
                            )
                        })
                    }
                    {currentTab === 2 &&
                        arraPhotoSwiper3.map((r, i) => {
                            return (
                                i === 0 ?
                                    <SwiperSlide key={r.photo + i}>
                                        <video src={r.photo[0]} loop muted autoPlay playsInline poster={r.photo[1]}></video>
                                    </SwiperSlide>
                                    :
                                    <SwiperSlide key={r.photo + i}>
                                        <LazyLoadImage
                                            placeholderSrc='blur'
                                            alt="image"
                                            height="100%"
                                            effect="blur"
                                            src={r.photo}
                                            width="100%" />
                                    </SwiperSlide>
                            )
                        })
                    }
                    {currentTab === 3 &&
                        arraPhotoSwiper4.map((r, i) => {
                            return (
                                i === 0 ?
                                    <SwiperSlide key={r.photo + i}>
                                        <video src={r.photo[0]} loop muted autoPlay playsInline poster={r.photo[1]}></video>
                                    </SwiperSlide>
                                    :
                                    <SwiperSlide key={r.photo + i}>
                                        <LazyLoadImage
                                            placeholderSrc='blur'
                                            alt="image"
                                            height="100%"
                                            effect="blur"
                                            src={r.photo}
                                            width="100%" />
                                    </SwiperSlide>
                            )
                        })
                    }
                    {currentTab === 4 &&
                        arraPhotoSwiper5.map((r, i) => {
                            return (
                                i === 0 ?
                                    <SwiperSlide key={r.photo + i}>

                                        <video src={r.photo[0]} loop muted autoPlay playsInline poster={r.photo[1]}></video>                                        </SwiperSlide>
                                    :
                                    <SwiperSlide key={r.photo + i}>
                                        <LazyLoadImage
                                            placeholderSrc='blur'
                                            alt="image"
                                            height="100%"
                                            effect="blur"
                                            src={r.photo}
                                            width="100%" />
                                    </SwiperSlide>
                            )
                        })
                    }
                    {currentTab === 5 &&
                        arraPhotoSwiper6.map((r, i) => {
                            return (
                                i === 0 ?
                                    <SwiperSlide key={r.photo + i}>

                                        <video src={r.photo[0]} loop muted autoPlay playsInline poster={r.photo[1]}></video>                                        </SwiperSlide>
                                    :
                                    <SwiperSlide key={r.photo + i}>
                                        <LazyLoadImage
                                            placeholderSrc='blur'
                                            alt="image"
                                            height="100%"
                                            effect="blur"
                                            src={r.photo}
                                            width="100%" />
                                    </SwiperSlide>

                            )
                        })
                    }
                    {currentTab === 6 &&
                        arraPhotoSwiper7.map((r, i) => {
                            return (
                                i === 0 || i === 1 || i === 2 || i === 3 ?
                                    <SwiperSlide key={r.photo + i}>

                                        <video src={r.photo[0]} loop muted autoPlay playsInline poster={r.photo[1]}></video>                                        </SwiperSlide>
                                    :
                                    <SwiperSlide key={r.photo + i}>
                                        <LazyLoadImage
                                            placeholderSrc='blur'
                                            alt="image"
                                            height="100%"
                                            effect="blur"
                                            src={r.photo}
                                            width="100%" />
                                    </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
                {!widthWindow &&
                    <div key={currentTab + 'Links'} className={styles.tabs}>
                        {AllTabs?.map(({ title, subTitle, text, color, href, id, route }) => {
                            return (
                                <LinkItem
                                    changePhoto={false}
                                    transition={true}
                                    OpenItem={() => setActiveDoor(true)}
                                    setCurrentTab={(id) => setCurrentTab(id)}
                                    key={title + id}
                                    id={id}
                                    title={title}
                                    subTitle={subTitle}
                                    text={text}
                                    color={color}
                                    href={href}
                                    route={route}
                                    currentTab={currentTab}
                                />
                            )
                        })}
                    </div>
                }
            </div>
        </>

    );
};

export default BlockRight;