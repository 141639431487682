import React from 'react';

const TabFourth = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" 
                 width="63.53"
                 height="82.28" viewBox="0 0 63.53 82.28">
                <defs>
                    <clipPath id="clipPath3">
                        <rect id="Прямоугольник_95" data-name="Прямоугольник 95" width="63.53"
                              height="82.28" fill="none"/>
                    </clipPath>
                </defs>
                <g id="LOGO" transform="translate(-809 -963)">
                    <g id="Layer_2" data-name="Layer 2" transform="translate(809 963)">
                        <g id="Layer_1" data-name="Layer 1">
                            <g id="Сгруппировать_123" data-name="Сгруппировать 123"
                               clipPath="url(#clipPath3)">
                                <path id="Контур_167" data-name="Контур 167"
                                      d="M45.35,61.41H43.17L38.7,74.1,34,61.22l-2,.39,5.72,15L37,78.45c-.57,1.47-1.31,2-2.55,2a6.079,6.079,0,0,1-1.249-.13l.289,1.86a5.691,5.691,0,0,0,.83.09c2.24,0,3.58-.93,4.67-3.87ZM31.86,76.53l-.32-1.76a8,8,0,0,1-1.4.16c-1.63,0-2.24-.67-2.24-2.2V63.2h3.77l-.29-1.79H27.9V55.82l-2.08.35v5.24H23.49V63.2h2.33v9.88c0,2.78,1.57,3.7,3.93,3.7a8.11,8.11,0,0,0,2.11-.25m-10-15.12H19.78V76.53h2.08Zm.48-5.14a1.6,1.6,0,1,0-1.5,1.53,1.53,1.53,0,0,0,1.5-1.53M17.26,74.74l-.32-2.11a10.37,10.37,0,0,1-6.3,2.3c-4.79,0-7.83-3.8-7.83-9.46S5.82,56,10.67,56a9.91,9.91,0,0,1,5.88,2.15L16.9,56a10.63,10.63,0,0,0-6.19-1.89C4.63,54.06.54,58.76.54,65.54s4,11.4,10,11.4a11.45,11.45,0,0,0,6.71-2.2"
                                      fill="#ec008c"/>
                                <path id="Контур_168" data-name="Контур 168"
                                      d="M61.36,40.66H53.11c.42-2.91,2-4.66,4.32-4.66s3.7,1.94,3.93,4.66m2.17,1.22c0-4.19-2-7.58-6.1-7.58-3.71,0-6.52,3-6.52,7.86,0,5.21,2.84,7.9,6.81,7.9a8.82,8.82,0,0,0,5.78-2.14L63.18,46a8.19,8.19,0,0,1-5.34,2.24c-3,0-4.76-2.21-4.82-5.92H63.53ZM49,49.74V38.9c0-2.87-1.5-4.6-4.51-4.6a8.15,8.15,0,0,0-5.3,2.46v-10L37,27.18V49.74h2.11V38.81c1.66-1.63,3.29-2.62,4.89-2.62,1.92,0,2.81,1.18,2.81,3.13V49.74ZM36.18,29.67l-.32-2H19.62v2h7.16V49.74H29V29.67ZM12.88,45A7.13,7.13,0,0,1,7,48.2c-2.84,0-4.79-1.82-4.79-4.25,0-2.27,1.6-4.06,4.34-5.21ZM20,49.39l-4.67-4.73a22.84,22.84,0,0,0,2.4-7.35l-2-.42A21.81,21.81,0,0,1,14,43.25L7.51,36.7C5.85,35,5.15,33.92,5.15,32.35c0-1.76,1.24-3.1,3.48-3.1a9.41,9.41,0,0,1,4.86,1.66l.41-2.2a10.48,10.48,0,0,0-5.21-1.38c-3.58,0-5.75,2.27-5.75,5.12,0,2,.8,3.35,2.3,4.95C2.21,38.81,0,40.82,0,44.14c0,3.58,2.68,6,7,6a8.81,8.81,0,0,0,7.22-3.68l3.55,3.52Z"
                                      fill="#ec008c"/>
                                <path id="Контур_169" data-name="Контур 169"
                                      d="M46.5,13.87H38.25c.42-2.91,2-4.67,4.32-4.67s3.7,2,3.93,4.67m2.17,1.21c0-4.18-2-7.57-6.1-7.57-3.71,0-6.52,3-6.52,7.86,0,5.21,2.84,7.89,6.8,7.89a8.78,8.78,0,0,0,5.79-2.14l-.32-1.91A8.18,8.18,0,0,1,43,21.44c-3,0-4.76-2.2-4.82-5.91H48.67ZM34,23V7.83H31.86V18.76c-1.66,1.63-3.29,2.59-4.89,2.59-1.92,0-2.81-1.15-2.81-3.1V7.83H22.05v10.8c0,2.88,1.47,4.63,4.51,4.63,2,0,3.77-1.08,5.43-2.55L32.34,23ZM19,23V0L16.84.38V23Zm-6.65-6.24c0,2.75-2.11,4.32-5.46,4.32H3.71V12.3h3c3.45,0,5.59,1.41,5.59,4.41m-1-10.22c0,2.3-1.63,4-4.76,4H3.71V2.81H6.58c3.17,0,4.7,1.38,4.7,3.68m3.16,10.1c0-2.94-1.72-4.64-4.34-5.37a5,5,0,0,0,3.35-4.83c0-3.29-2.27-5.5-6.8-5.5H1.53V23H6.61c4.73,0,7.83-2.15,7.83-6.36"
                                      fill="#fff"/>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default TabFourth;