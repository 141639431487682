import React, { useEffect, useState } from 'react';
import videoMain from '../../media/video/yakov.mp4'
import videoMainPoster from '../../media/video/yakov.webp'
import "swiper/swiper-bundle.css";
import styles from './index.module.scss'
import classNames from 'classnames'

import { arrayDataTab, linkItemThree, linkItemForce, data, dataDropDown, dataTel } from '../../data/dataForHidenBlock'
import 'react-lazy-load-image-component/src/effects/blur.css';
import Marquee from "react-fast-marquee";
import { useForm } from "react-hook-form";
import axios from "axios";

import LinkItem from "../LinkItem/LinkItem"
import BlockLeft from "../BlockLeft/blockLeft"
import BlockRight from "../BlockRight/blockRight"
import Header from "../Header/header"
import MobileButtons from "../MobileButtons/mobileButtons"

const HomePage = () => {
    const widthWindow = document.documentElement.clientWidth <= 768

    const url = "https://icreate-campaign.com/Admin/WS/InsertLeadCRM";

    const urlLocation = document.location.href
    function routing() {
        const url = document.location.href

        if (url.includes('natania')) {
            return 0
        } else if (url.includes('ramat-gan')) {
            return 1
        } else if (url.includes('tel-aviv')) {
            return 2
        } else if (url.includes('bat-yam')) {
            return 3
        } else if (url.includes('ramat-hasharon')) {
            return 4
        } else if (url.includes('beer-yaakov')) {
            return 5
        } else if (url.includes('tirat-carmel')) {
            return 6;
        }
        return '';
    }
    const [activeDoor, setActiveDoor] = useState(routing() !== '')
    const [activeDoorAnimation, setActiveDoorAnimation] = useState(routing() !== '')
    const [currentTab, setCurrentTabState] = useState(routing())
    const [submitForm, setSubmitForm] = useState(false)
    const [valueDropDown, setValueDropDown] = useState('')
    const [activeDropDown, setActiveDropDown] = useState(false)
    const [campid, setCampid] = useState('')
    const [publicKey, setPublicKey] = useState('')
    const [activePopUp, setActivePopUp] = useState(false)
    const [cookie, setCookie] = useState(true)
    const [mobileFormShown, setMobileForm] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [onpopstate, setOnpopstate] = useState('')

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        data['camp_id'] = campid
        data['public_key_token'] = publicKey
        delete data['select']
        const AddColomSPA = (formData) => {
            axios({
                method: "post",
                url: url,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            })
        };
        var form_data = new FormData();

        for (var key in data) {
            form_data.append(key, data[key]);
        }
        AddColomSPA(form_data)

        window.gtag('event', 'conversion', { 'send_to': 'AW-695084254/JZ8-CKK9lKICEN7JuMsC' });
        window.fbq('track', 'Lead');
        setSubmitForm(true)
    }

    function setMobileFormFalse(e) {
        e.preventDefault();
        setMobileForm(!mobileFormShown)
    }

    function getSearchParameters() {
        let prmstr = window.location.search.substr(1);
        return prmstr !== null && prmstr !== "" ? transformToAssocArray(prmstr) : {};
    }

    function transformToAssocArray(prmstr) {
        let params = {};
        let prmarr = prmstr.split("&");
        for (let i = 0; i < prmarr.length; i++) {
            let tmparr = prmarr[i].split("=");
            params[tmparr[0]] = tmparr[1];
        }
        return params;
    }

    useEffect(() => {
        if (activeDoor) {
            const timer = setTimeout(() => {
                setActiveDoorAnimation(true)
            }, 200);
            return () => clearTimeout(timer);
        } else {
            setCurrentTab("")
        }
    }, [activeDoor])

    useEffect(() => {
        const filteredItem = dataDropDown.filter(({ text, id }) => id === currentTab)
        setValueDropDown(filteredItem.length ? filteredItem[0].text : '')
        setSubmitForm(false)
        setCampid(data[currentTab]?.camp_id)
        setPublicKey(data[currentTab]?.public_key_token)
    }, [])

    useEffect(() => {
        const filteredItem = dataDropDown.filter(({ text, id }) => id === currentTab)
        setValueDropDown(filteredItem.length ? filteredItem[0].text : '')
        setSubmitForm(false)
        setCampid(data[currentTab]?.camp_id)
        setPublicKey(data[currentTab]?.public_key_token)
    }, [currentTab])

    const setCurrentTab = (tab) => {
        if (currentTab === tab) {
            return false
        }
        if (activeDoor && document.documentElement.clientWidth > 768) {
            setActiveDoorAnimation(false)
            const timer2 = setTimeout(() => {
                setCurrentTabState(tab)
            }, 400);
            const timer = setTimeout(() => {
                setActiveDoorAnimation(true)
            }, 700);
            return () => {
                clearTimeout(timer2)
                clearTimeout(timer)
            }
        } else {
            setCurrentTabState(tab)
        }
    }

    const closeDoorBlock = () => {
        window.history.pushState({}, '', "/" + window.location.search);
        setActiveDoorAnimation(false)
        setValueDropDown("")
        const timer = setTimeout(() => {
            setActiveDoor(false)
        }, 500);
        return () => clearTimeout(timer);
    }

    let AllTabs = []
    linkItemThree.map(r => AllTabs.push(r))
    linkItemForce.map(r => AllTabs.push(r))

    const params = getSearchParameters();

    useEffect(() => {
        const cookie = localStorage.getItem("cookie")
        if (cookie === "true") {
            setCookie(false)
        } else {
            localStorage.setItem("cookie", "false")
        }
        setTimeout(() => {
            setLoaded(true)
        }, 3200);
    }, [])


    window.onpopstate = function (event) {
        setOnpopstate(event.state)
    };

    const callMe = (e) => {
        e.preventDefault();
        const utm_source = params?.utm_source === 'facebook' ? 'facebook' : 'google';
        const index = currentTab !== '' ? currentTab : 7
        window.gtag('event', 'conversion', { 'send_to': 'AW-695084254/JZ8-CKK9lKICEN7JuMsC' });
        window.fbq('track', 'Lead');
        const tel = dataTel[index][utm_source]
        window.location.href = "tel:" + tel;
    }

    useEffect(() => {
        const r = routing()
        if (r !== '') {
            setCurrentTab(r)
            const timer = setTimeout(() => {
                setActiveDoor(true)
            }, 500);
            return clearTimeout(timer)
        } else {
            closeDoorBlock()
        }
    }, [onpopstate, urlLocation])

    return (
        <>
            {
                cookie &&
                <div className={styles.cookieSuccses}>
                    <span>אתר זה משתמש בעוגיות [Cookies] למטרות שונות, כמפורט בהצהרת הפרטיות.</span>
                    <span onClick={() => setActivePopUp(!activePopUp)} className={styles.cookieSuccsesLink}>​לחץ ללמוד על הצהרת הפרטיות שלנו</span>
                    <span onClick={() => { setActivePopUp(false); setCookie(false); localStorage.setItem("cookie", "true") }} className={styles.cookieSuccsesButton}>מאשר שקראתי</span>
                </div>
            }
            {widthWindow &&
                <Header cookie={cookie} setActiveDoor={() => setActiveDoor(false)} closeDoorBlock={() => closeDoorBlock()}>
                    {AllTabs.map(({ title, subTitle, text, color, href, id, photo, route }) => {
                        return (
                            <LinkItem key={"Header" + id} route={route} isHeader={true} photo={photo} setCurrentTab={(id) => setCurrentTab(id)} changePhoto={true} OpenItem={() => setActiveDoor(true)} id={id} title={title}
                                subTitle={subTitle} text={text} color={color} href={href} />
                        )
                    })}
                </Header>}
            <div className={classNames(styles.wrapperMain, {
                [styles.zIndex]: mobileFormShown
            })}>
                <div className={styles.headerWrapper}>
                    {currentTab === "" ?
                        <>
                            {loaded ?
                                <video loop muted autoPlay playsInline poster={videoMainPoster}>
                                    <source src={videoMain} type='video/mp4'></source>
                                </video>
                                :
                                <img alt="Main banner" className={styles.object} src={videoMainPoster} />
                            }
                        </>
                        :
                        <img alt="Main banner" className={styles.object} src={videoMainPoster} />
                    }
                    <div className={styles.headerWrapperTitle}>
                        <Marquee direction={'right'} speed={100} gradient={false}>
                            פטור ממדד לשנה / הטבה בשינויי דיירים! / ליווי אדריכלי לעיצוב / פטור משכ"ט עו"ד / תנאי מימון
                            20/80 /
                            פטור ממדד לשנה! / ליווי אדריכלי לעיצוב הדירה / הטבה בשינויי דיירים! / פטור משכ"ט עו"ד / תנאי
                            מימון
                            20/80
                        </Marquee>
                    </div>
                </div>
                <div className={styles.blockInfo}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.blockInfoForm}>
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 725.707 97.937">
                                <defs>
                                    <clipPath id="clip-logo">
                                        <rect width="725.707" height="97.937" transform="translate(0 0)" fill="none" />
                                    </clipPath>
                                </defs>
                                <g id="ROTSHTIME_LOGO" data-name="ROTSHTIME LOGO" transform="translate(0 0)">
                                    <g id="Сгруппировать_93" data-name="Сгруппировать 93">
                                        <g id="Сгруппировать_92" data-name="Сгруппировать 92" clipPath="url(#clip-logo)">
                                            <path id="Контур_141" data-name="Контур 141" d="M642.172,0V27.618h54.91V97.937h28.624V0ZM600.31,97.937h29.208V0H600.31Zm-122.672,0,110.8-.2V37.8c0-23.113-13.826-37.8-37.971-37.8H523.98V27.814h22.782C557.472,27.814,560,33.1,560,44.659V69.73H506.066V0L477.637.2ZM435.385,0V51.318c0,13.32-6.815,18.608-16.746,18.608h-8.178V0H381.448V69.927H357.692V0H328.874V97.937H421.95c22.587,0,41.67-16.65,41.67-40.742V0Z" fill="#fff" />
                                            <path id="Контур_142" data-name="Контур 142" d="M206.59,97.937l110.8-.2V37.8c0-23.113-13.826-37.8-37.971-37.8H252.933V27.814h22.783c10.709,0,13.241,5.289,13.241,16.846V69.731H235.019V0L206.59.2ZM163.949,57.2h29.987V0H163.949Zm-42.058,0h29.987V0H121.891ZM28.818,69.731V27.814H68.152c10.32,0,13.241,3.526,13.241,14.691V69.731ZM0,97.937l110.212-.2V36.824C110.212,13.711,95.8,0,72.046,0H0Z" fill="#e50d3f" />
                                            <path id="Контур_143" data-name="Контур 143" d="M53.773,58.621a7.395,7.395,0,1,0-7.351-7.395,7.373,7.373,0,0,0,7.351,7.395" fill="#fff" />
                                        </g>
                                    </g>
                                    <rect className="clockMinutes" width="5.881" height="28.836" x="50.832" y="20.912" transform-origin="53.9px 51px" fill="#fff" />
                                    <rect className="clockSeconds" width="5.881" height="49.541" x="50.832" y="53.782" transform-origin="53.9px 51px" fill="#fff" />
                                </g>
                            </svg>
                            <p className={styles.subTitle}>
                                <span className={styles.justyify}>הטבות סופשנה <span className={styles.fontPink}>חוזרות ביג טיים</span></span>

                                <span className={styles.fontPink}>!</span>
                            </p>
                            <p className={classNames(styles.subTitleText, styles.justyify)}>
                                רוטשטיין עם הטבות גדולות מתמיד בכל הפרויקטים שלנו ברחבי הארץ
                            </p>
                            {!submitForm || mobileFormShown ?
                                <form className={classNames(styles.formMain, {
                                    [styles.shownForm]: mobileFormShown
                                })} onSubmit={handleSubmit(onSubmit)}>
                                    {!submitForm &&
                                        <>
                                            <p className={styles.formTitle}>
                                                זה הזמן להתקדם לדירה בהטבה! מלאו פרטים ונחזור אליכם בהקדם
                                            </p>
                                            <div className={styles.formInput}>
                                                <div>
                                                    {errors.full_name && <span className={styles.error}>⚠ אנא הזן שם מלא</span>}
                                                    <input onKeyPress={(event) => {
                                                        if (!/[a-zA-Z\u0590-\u05FF\u200f\u200e ]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }} className={styles.fullName} {...register("full_name", { required: true })} type="text" />
                                                    <span>שם מלא:</span>
                                                </div>
                                                <div>
                                                    {errors.email && <span className={styles.error}>⚠ אנא הזן כתובת מייל חוקית</span>}
                                                    <input className={styles.email} {...register("email", { required: true })} type="email" />
                                                    <span>מייל:</span>
                                                </div>
                                                <div>
                                                    {errors.phone && <span className={styles.error}>⚠ אנא הזן מספר פלאפון תקין</span>}
                                                    <input onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }} className={styles.optOut} {...register("phone", { required: true })} type="text" />
                                                    <span>טלפון:</span>
                                                </div>
                                                <div>
                                                    <input readOnly={true}
                                                        onClick={() => setActiveDropDown(true)}
                                                        value={valueDropDown} className={styles.phone}
                                                        {...register("select", { required: true })}
                                                        type="text" />
                                                    <span>פרויקט {`>`}</span>
                                                    {activeDropDown &&
                                                        <div className={styles.dropDownItem}>
                                                            {dataDropDown.map(({ text, id }) => {
                                                                return (
                                                                    <span key={"dataDropDown" + id}
                                                                        onClick={() => {
                                                                            setCurrentTab(id);
                                                                            setActiveDropDown(false);
                                                                            setValueDropDown(text)
                                                                        }}>
                                                                        {text}
                                                                    </span>
                                                                )
                                                            })}
                                                        </div>
                                                    }
                                                </div>

                                                <input {...register("media_source")} type="hidden" value={params?.utm_source ? params?.utm_source : ''} />
                                                <input {...register("media_channel")} type="hidden" value={params?.utm_source ? params?.utm_source : ''} />
                                                <input {...register("utm_source")} type="hidden" value={params?.utm_source ? params?.utm_source : ''} />
                                                <input {...register("utm_medium")} type="hidden" value={params?.utm_medium ? params?.utm_medium : ''} />
                                                <input {...register("utm_campaign")} type="hidden" value={params?.utm_campaign ? params?.utm_campaign : ''} />
                                                <input {...register("utm_term")} type="hidden" value={params?.utm_term ? params?.utm_term : ''} />
                                                <input {...register("utm_content")} type="hidden" value={params?.utm_content ? params?.utm_content : ''} />
                                            </div>
                                            <div className={styles.buttonWrapper}>
                                                <svg className={styles.checkboxSvg}
                                                    xmlns="http://www.w3.org/2000/svg" width="196.721" height="41.151"
                                                    viewBox="0 0 196.721 41.151">
                                                    <defs>
                                                        <clipPath id="clipPath1">
                                                            <rect id="Прямоугольник_70" data-name="Прямоугольник 70" width="196.721"
                                                                height="41.151" transform="translate(0 0)" fill="none" />
                                                        </clipPath>
                                                    </defs>
                                                    <g id="ROTSHTEIN_LOGO" data-name="ROTSHTEIN LOGO" transform="translate(0 0)">
                                                        <g id="Сгруппировать_95" data-name="Сгруппировать 95">
                                                            <g id="Сгруппировать_94" data-name="Сгруппировать 94"
                                                                clipPath="url(#clipPath1)">
                                                                <path id="Контур_144" data-name="Контур 144"
                                                                    d="M194.761,8.388a8.228,8.228,0,1,0-8.229,8.28,8.253,8.253,0,0,0,8.229-8.28m-8.229,16.767-9.988,15.152h19.976ZM157.993,40.307h12.83V.109h-12.83Zm-16.681-16.49H147.2V.5h-18.73V6.139h12.841ZM128.849,36.629l-2.88-4.163H124.9l5.376,7.6h-5.241v.825h6.163V40l-1.773-2.562,2.745-4.953-1.021-.017Zm-9.923-12.813h5.981V.5h-5.981Zm-2.061,17.073h.886V32.466h-.886ZM90.411,23.816l24.942-.048V8.986c0-5.226-3.292-8.488-8.668-8.488H100.8V6.186h4.914c2.826,0,3.8,1.075,3.8,3.871V18.08H96.3V.5L90.411.542Zm.49,8.65h-.886v8.424H90.9ZM86.75,14.395V.5H80.863V13.37c0,3.265-1.852,4.758-4.636,4.758H75.115V.5H69.133v17.63H63.617V.5H57.639V23.816H77.108c5.379,0,9.643-3.73,9.643-9.422M61.194,32.466H55.163v.824H60.29v6.775H55.18v.825h6.933v-.825h-.919Zm-32.159-8.65,24.94-.048V8.986C53.975,3.76,50.685.5,45.306.5H39.419V6.186h4.914c2.829,0,3.8,1.075,3.8,3.871V18.08H34.924V.5L29.035.542Zm-9.829-9.84h6.166V.5H19.206Zm-9.643,0h6.166V.5H9.563ZM6.439,40.889h.886V32.466H.846v.824H6.439ZM.109,27.861H5.95V.5H.109ZM1.817,35.77H.931v5.123h.886Zm13.655-3.3h-.9V37h.9Zm8.164,0h-.9V37h.9Zm8.133,7.617H30.6v.807h1.174a2.387,2.387,0,0,0,2.461-2.627v-5.8H30.815v.792h2.543v5.072a1.579,1.579,0,0,1-1.59,1.752m15.48-7.617H41.222v.824h5.124v6.775H41.239v.825h6.932v-.825h-.922ZM75.218,40.2v.842h.117a2.134,2.134,0,0,0,2.295-2.409V33.29h4.3v7.6H82.8V32.466H75.419v.824h1.323v5.359c0,1.063-.471,1.534-1.524,1.552m23.9-.118h-1.17v.807h1.17a2.388,2.388,0,0,0,2.462-2.627v-5.8H98.16v.792h2.546v5.072a1.581,1.581,0,0,1-1.593,1.752m10.459-7.617h-.9V37h.9Zm34.257,0h-5.11v.81h1.024v7.614h.886V33.276h3.216a1.69,1.69,0,0,1,1.876,1.852V40.1h-3.485v.792h4.337V35.213a2.49,2.49,0,0,0-2.745-2.747"
                                                                    fill="#fff" />
                                                                <path id="Контур_145" data-name="Контур 145"
                                                                    d="M194.761,8.388a8.228,8.228,0,1,0-8.229,8.28,8.253,8.253,0,0,0,8.229-8.28m-8.229,16.767-9.988,15.152h19.976ZM157.993,40.307h12.83V.109h-12.83Zm-16.681-16.49H147.2V.5h-18.73V6.139h12.841ZM128.849,36.629l-2.88-4.163H124.9l5.376,7.6h-5.241v.825h6.163V40l-1.773-2.562,2.745-4.953-1.021-.017Zm-9.923-12.813h5.981V.5h-5.981Zm-2.061,17.073h.886V32.466h-.886ZM90.411,23.816l24.942-.048V8.986c0-5.226-3.292-8.488-8.668-8.488H100.8V6.186h4.914c2.826,0,3.8,1.075,3.8,3.871V18.08H96.3V.5L90.411.542Zm.49,8.65h-.886v8.424H90.9ZM86.75,14.395V.5H80.863V13.37c0,3.265-1.852,4.758-4.636,4.758H75.115V.5H69.133v17.63H63.617V.5H57.639V23.816H77.108c5.379,0,9.643-3.73,9.643-9.422M61.194,32.466H55.163v.824H60.29v6.775H55.18v.825h6.933v-.825h-.919Zm-32.159-8.65,24.94-.048V8.986C53.975,3.76,50.685.5,45.306.5H39.419V6.186h4.914c2.829,0,3.8,1.075,3.8,3.871V18.08H34.924V.5L29.035.542Zm-9.829-9.84h6.166V.5H19.206Zm-9.643,0h6.166V.5H9.563ZM6.439,40.889h.886V32.466H.846v.824H6.439ZM.109,27.861H5.95V.5H.109ZM1.817,35.77H.931v5.123h.886Zm13.655-3.3h-.9V37h.9Zm8.164,0h-.9V37h.9Zm8.133,7.617H30.6v.807h1.174a2.387,2.387,0,0,0,2.461-2.627v-5.8H30.815v.792h2.543v5.072a1.579,1.579,0,0,1-1.59,1.752m15.48-7.617H41.222v.824h5.124v6.775H41.239v.825h6.932v-.825h-.922ZM75.218,40.2v.842h.117a2.134,2.134,0,0,0,2.295-2.409V33.29h4.3v7.6H82.8V32.466H75.419v.824h1.323v5.359c0,1.063-.471,1.534-1.524,1.552m23.9-.118h-1.17v.807h1.17a2.388,2.388,0,0,0,2.462-2.627v-5.8H98.16v.792h2.546v5.072a1.581,1.581,0,0,1-1.593,1.752m10.459-7.617h-.9V37h.9Zm34.257,0h-5.11v.81h1.024v7.614h.886V33.276h3.216a1.69,1.69,0,0,1,1.876,1.852V40.1h-3.485v.792h4.337V35.213a2.49,2.49,0,0,0-2.745-2.747"
                                                                    fill="none" stroke="#f5f6f7" strokeWidth="0.217" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <div className={styles.buttonWrapperSecond}>
                                                    <div className={styles.checkboxWrapper}>
                                                        <input {...register("email_opt_out", { required: false })} type="checkbox" id="email-checkbox" />
                                                        <label className={styles.checkboxTitle} htmlFor="email-checkbox">אני מאשר/ת קבלת מידע שיווקי</label>
                                                    </div>
                                                    <button type='submit' className={styles.checkboxButton}>
                                                        שלח
                                                    </button>
                                                </div>

                                            </div>
                                            <div className={styles.checkboxCreate}>
                                                <p>**ההטבות משתנות מפרויקט לפרויקט *בכפוף לתקנון *מוגבל למספר דירות בכל פרויקט *לחותמים על חוזה עד ה- 31.12.2021 בלבד *ההדמיות להמחשה בלבד. ט.ל.ח.</p>
                                            </div>
                                            <button className={styles.closeFormButton} onClick={setMobileFormFalse}>
                                                <span></span>
                                                <span></span>
                                            </button>
                                            <button className={styles.downSideButton} onClick={callMe}>התקשרו {`>>`}</button>
                                        </>}
                                    {submitForm && mobileFormShown &&
                                        <div className={classNames(styles.flexAroud, styles.succsessSubmit)}>
                                            <p>
                                                <span>תודה,</span><br /><span>פרטיך התקבלו</span><br /><span>בהצלחה!</span>
                                            </p>
                                            <button className={styles.closeFormButton} onClick={setMobileFormFalse}>
                                                <span></span>
                                                <span></span>
                                            </button>
                                            <div >
                                                <svg className={styles.checkboxSvg}
                                                    xmlns="http://www.w3.org/2000/svg" width="196.721" height="41.151"
                                                    viewBox="0 0 196.721 41.151">
                                                    <defs>
                                                        <clipPath id="clipPath1">
                                                            <rect id="Прямоугольник_70" data-name="Прямоугольник 70" width="196.721"
                                                                height="41.151" transform="translate(0 0)" fill="none" />
                                                        </clipPath>
                                                    </defs>
                                                    <g id="ROTSHTEIN_LOGO" data-name="ROTSHTEIN LOGO" transform="translate(0 0)">
                                                        <g id="Сгруппировать_95" data-name="Сгруппировать 95">
                                                            <g id="Сгруппировать_94" data-name="Сгруппировать 94"
                                                                clipPath="url(#clipPath1)">
                                                                <path id="Контур_144" data-name="Контур 144"
                                                                    d="M194.761,8.388a8.228,8.228,0,1,0-8.229,8.28,8.253,8.253,0,0,0,8.229-8.28m-8.229,16.767-9.988,15.152h19.976ZM157.993,40.307h12.83V.109h-12.83Zm-16.681-16.49H147.2V.5h-18.73V6.139h12.841ZM128.849,36.629l-2.88-4.163H124.9l5.376,7.6h-5.241v.825h6.163V40l-1.773-2.562,2.745-4.953-1.021-.017Zm-9.923-12.813h5.981V.5h-5.981Zm-2.061,17.073h.886V32.466h-.886ZM90.411,23.816l24.942-.048V8.986c0-5.226-3.292-8.488-8.668-8.488H100.8V6.186h4.914c2.826,0,3.8,1.075,3.8,3.871V18.08H96.3V.5L90.411.542Zm.49,8.65h-.886v8.424H90.9ZM86.75,14.395V.5H80.863V13.37c0,3.265-1.852,4.758-4.636,4.758H75.115V.5H69.133v17.63H63.617V.5H57.639V23.816H77.108c5.379,0,9.643-3.73,9.643-9.422M61.194,32.466H55.163v.824H60.29v6.775H55.18v.825h6.933v-.825h-.919Zm-32.159-8.65,24.94-.048V8.986C53.975,3.76,50.685.5,45.306.5H39.419V6.186h4.914c2.829,0,3.8,1.075,3.8,3.871V18.08H34.924V.5L29.035.542Zm-9.829-9.84h6.166V.5H19.206Zm-9.643,0h6.166V.5H9.563ZM6.439,40.889h.886V32.466H.846v.824H6.439ZM.109,27.861H5.95V.5H.109ZM1.817,35.77H.931v5.123h.886Zm13.655-3.3h-.9V37h.9Zm8.164,0h-.9V37h.9Zm8.133,7.617H30.6v.807h1.174a2.387,2.387,0,0,0,2.461-2.627v-5.8H30.815v.792h2.543v5.072a1.579,1.579,0,0,1-1.59,1.752m15.48-7.617H41.222v.824h5.124v6.775H41.239v.825h6.932v-.825h-.922ZM75.218,40.2v.842h.117a2.134,2.134,0,0,0,2.295-2.409V33.29h4.3v7.6H82.8V32.466H75.419v.824h1.323v5.359c0,1.063-.471,1.534-1.524,1.552m23.9-.118h-1.17v.807h1.17a2.388,2.388,0,0,0,2.462-2.627v-5.8H98.16v.792h2.546v5.072a1.581,1.581,0,0,1-1.593,1.752m10.459-7.617h-.9V37h.9Zm34.257,0h-5.11v.81h1.024v7.614h.886V33.276h3.216a1.69,1.69,0,0,1,1.876,1.852V40.1h-3.485v.792h4.337V35.213a2.49,2.49,0,0,0-2.745-2.747"
                                                                    fill="#fff" />
                                                                <path id="Контур_145" data-name="Контур 145"
                                                                    d="M194.761,8.388a8.228,8.228,0,1,0-8.229,8.28,8.253,8.253,0,0,0,8.229-8.28m-8.229,16.767-9.988,15.152h19.976ZM157.993,40.307h12.83V.109h-12.83Zm-16.681-16.49H147.2V.5h-18.73V6.139h12.841ZM128.849,36.629l-2.88-4.163H124.9l5.376,7.6h-5.241v.825h6.163V40l-1.773-2.562,2.745-4.953-1.021-.017Zm-9.923-12.813h5.981V.5h-5.981Zm-2.061,17.073h.886V32.466h-.886ZM90.411,23.816l24.942-.048V8.986c0-5.226-3.292-8.488-8.668-8.488H100.8V6.186h4.914c2.826,0,3.8,1.075,3.8,3.871V18.08H96.3V.5L90.411.542Zm.49,8.65h-.886v8.424H90.9ZM86.75,14.395V.5H80.863V13.37c0,3.265-1.852,4.758-4.636,4.758H75.115V.5H69.133v17.63H63.617V.5H57.639V23.816H77.108c5.379,0,9.643-3.73,9.643-9.422M61.194,32.466H55.163v.824H60.29v6.775H55.18v.825h6.933v-.825h-.919Zm-32.159-8.65,24.94-.048V8.986C53.975,3.76,50.685.5,45.306.5H39.419V6.186h4.914c2.829,0,3.8,1.075,3.8,3.871V18.08H34.924V.5L29.035.542Zm-9.829-9.84h6.166V.5H19.206Zm-9.643,0h6.166V.5H9.563ZM6.439,40.889h.886V32.466H.846v.824H6.439ZM.109,27.861H5.95V.5H.109ZM1.817,35.77H.931v5.123h.886Zm13.655-3.3h-.9V37h.9Zm8.164,0h-.9V37h.9Zm8.133,7.617H30.6v.807h1.174a2.387,2.387,0,0,0,2.461-2.627v-5.8H30.815v.792h2.543v5.072a1.579,1.579,0,0,1-1.59,1.752m15.48-7.617H41.222v.824h5.124v6.775H41.239v.825h6.932v-.825h-.922ZM75.218,40.2v.842h.117a2.134,2.134,0,0,0,2.295-2.409V33.29h4.3v7.6H82.8V32.466H75.419v.824h1.323v5.359c0,1.063-.471,1.534-1.524,1.552m23.9-.118h-1.17v.807h1.17a2.388,2.388,0,0,0,2.462-2.627v-5.8H98.16v.792h2.546v5.072a1.581,1.581,0,0,1-1.593,1.752m10.459-7.617h-.9V37h.9Zm34.257,0h-5.11v.81h1.024v7.614h.886V33.276h3.216a1.69,1.69,0,0,1,1.876,1.852V40.1h-3.485v.792h4.337V35.213a2.49,2.49,0,0,0-2.745-2.747"
                                                                    fill="none" stroke="#f5f6f7" strokeWidth="0.217" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <button className={styles.downSideButton} onClick={callMe}>התקשרו {`>>`}</button>
                                            </div>
                                        </div>
                                    }
                                </form>
                                :
                                <div className={styles.succsessSubmit}>
                                    <p>
                                        <span>תודה,</span><br /><span>פרטיך התקבלו</span><br /><span>בהצלחה!</span>
                                    </p>
                                </div>
                            }

                        </div>
                        <div className={styles.wrapperLinks}>
                            <div className={styles.wrapperLinksFirst}>
                                {linkItemThree.map(({ title, subTitle, text, color, href, id, photo, route }) => {
                                    return (
                                        <LinkItem route={route} photo={photo} setCurrentTab={(id) => setCurrentTab(id)} changePhoto={true} OpenItem={() => setActiveDoor(true)} key={id} id={id} title={title}
                                            subTitle={subTitle} text={text} color={color} href={href} />
                                    )
                                })}
                            </div>
                            <div className={styles.wrapperLinksFirst}>
                                {linkItemForce.map(({ title, subTitle, text, color, href, id, photo, route }) => {
                                    return (
                                        <LinkItem route={route} photo={photo} setCurrentTab={(id) => setCurrentTab(id)} changePhoto={true} OpenItem={() => setActiveDoor(true)} key={id} id={id} title={title}
                                            subTitle={subTitle} text={text} color={color} href={href} />
                                    )
                                })}
                            </div>
                            <div className={styles.wrapperLinksMob}>
                                {AllTabs.map(({ title, subTitle, text, color, href, id, photo, route }) => {
                                    return (
                                        <LinkItem route={route} photo={photo} setCurrentTab={(id) => setCurrentTab(id)} changePhoto={true} OpenItem={() => setActiveDoor(true)} key={id} id={id} title={title}
                                            subTitle={subTitle} text={text} color={color} href={href} />
                                    )
                                })}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {
                (currentTab !== "" || loaded) &&
                <div className={classNames(styles.wrapperDoor, {
                    [styles.active]: activeDoor
                })}>
                    <BlockLeft
                        currentTab={currentTab}
                        arrayDataTab={arrayDataTab}
                        activeDoorAnimation={activeDoorAnimation}
                        closeDoorBlock={() => closeDoorBlock()}
                        params={params}
                        data={data}
                        campid={campid}
                        publicKey={publicKey}
                        key={currentTab + 'BlockLeft'}
                    />
                    <BlockRight
                        currentTab={currentTab}
                        setCurrentTab={(id) => setCurrentTab(id)}
                        setActiveDoor={() => setActiveDoor(true)}
                        activeDoorAnimation={activeDoorAnimation}
                        AllTabs={AllTabs}
                        key={currentTab + 'BlockRight'}
                    />
                </div >
            }
            {
                // !mobileFormShown && 
                // <MobileButtons
                //     callMe={(e) => { callMe(e) }}
                //     setMobileFormFalse={(e) => { setMobileFormFalse(e) }}
                // />
            }
            {currentTab !== "" && 
            <button className={styles.footerButton} onClick={callMe}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M19.22,15.25l-2.52-0.29c-0.61-0.07-1.21,0.14-1.64,0.57l-1.85,1.85c-1.419-0.72-2.706-1.659-3.818-2.772 C8.279,13.496,7.34,12.209,6.62,10.79l1.85-1.85C8.9,8.51,9.11,7.91,9.04,7.3L8.75,4.78C8.63,3.77,7.78,3.01,6.76,3.01H5.03 c-1.13,0-2.07,0.94-2,2.07c0.265,4.269,2.104,8.108,4.943,10.947c2.839,2.839,6.678,4.678,10.947,4.943c1.13,0.07,2.07-0.87,2.07-2 v-1.73C20.99,16.22,20.23,15.37,19.22,15.25z"></path>
                </svg>
            </button>}
            {
                activePopUp &&
                <div className={styles.popUp}>
                    <div className={styles.popUpBlock}>
                        <p className={styles.popUpTitle}>
                            מדיניות פרטיות
                        </p>
                        <p className={styles.popUpText}>
                            1. הפרטים שאנו מבקשים באתר / עמודי הנחיתה שלנו כגון: פרטי צור קשר של שם, טלפון ומייל משמשים אותנו להצעת שירותי החברה המפרסמת בלבד.  ההצעות יכולות להתבצע בשיחות טלפוניות, משלוח הודעות סמס, מיילים, וואצאפ ואמצעים אלקטרוניים דומים.
                        </p>
                        <p className={styles.popUpText}>
                            2. המידע שיתקבל לא יימסר לצד שלישי ללא רשותו המפורשת של הלקוח. חשוב לציין שאנו משתמשים בשירותים של ספקי צד שלישי לצורך ביצוע טכני של דיוורים במייל במסרונים ומערכת ניהול לקוחות כך שהנתונים מועברים לספקים אלה אך ורק עבור פירסום לחברה המפרסמת .
                        </p>
                        <p className={styles.popUpText}>
                            3. אנו משתמשים באיסוף אוטומטי של מידע לצורכי סטטיסטיקה והגשת מודעות פרסום מותאמות אישית ללקוח באמצעים הבאים:<br />
                            -גוגל אנליטיקס לצורך ניתוח פעילות מבקרי האתר.<br />
                            -פיקסל המרות לצורך בדיקת יעילות הפרסום ממקורות מדיה כגון מודעות גוגל, מודעות פייסבוק, תנועה אורגנית, וכדומה<br />
                            -תגי שיווק מחדש של גוגל ופייסבוק לצורך הצגת מודעות מותאמות אישית.
                        </p>
                        <p className={styles.popUpText}>
                            4.שימוש בעוגיות  cookies
                        </p>
                        <p className={styles.popUpText}>
                            כאשר אתה מבקר באתר או בעמודי נחיתה שלנו, מותקן במכשיר שבו אתה משתמש קובצי cookies<br />
                            אשר מאפשרים להתאים ולהציע לך מוצרים העשויים לעניין אותך.<br />
                            חלק מה cookies בהם אנו נעזרים מקורם בצד שלישי, שרותי הפרסום של פייסבוק, גוגל ורשתות חברתיות.<br />
                            כדי לא לקבל עוגיות cookies ניתן לחסום אפשרות זו בדפדפן שלך
                        </p>
                        <p className={styles.popUpText}>
                            להסרה משרותי גוגל אנליטיקס<br />
                            <a target="_blank" rel="noreferrer" href=" https://tools.google.com/dlpage/gaoptout"> https://tools.google.com/dlpage/gaoptout</a>
                        </p>
                        <p className={styles.popUpText}>
                            להסרה משרותי הפרסום של גוגל  / התאמת אישית של מודעות<br />
                            <a target="_blank" rel="noreferrer" href="https://www.google.com/settings/u/0/ads/authenticated">https://www.google.com/settings/u/0/ads/authenticated</a>
                        </p>
                        <p className={styles.popUpText}>
                            למידע על השימוש של גוגל בעוגיות<br />
                            <a target="_blank" rel="noreferrer" href="https://www.google.com/intl/en/policies/technologies/ads">https://www.google.com/intl/en/policies/technologies/ads</a>
                        </p>
                        <p className={styles.popUpText}>
                            להסרה/ שליטה בשרות הפרסומות של פייסבוק<br />
                            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/policies/cookies">https://www.facebook.com/policies/cookies</a>
                        </p>
                        <div onClick={() => setActivePopUp(!activePopUp)} className={styles.cross}>
                            <span className={styles.crossOne}></span>
                            <span className={styles.crossSecond}></span>
                        </div>
                    </div>
                </div>
            }
        </>

    );
};

export default HomePage;

