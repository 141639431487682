import React, { useEffect, useState } from 'react';
import styles from './index.module.scss'
import classNames from "classnames";
import TabOne from "../../svg/tabOne";
import TabSecond from "../../svg/tabSecond";
import TabThird from "../../svg/TabThird";
import MainSvgTab from "../../svg/mainSvgTab";
import TabFourth from "../../svg/tabFourth";
import TabFifth from "../../svg/tabFifth";
import TabSixth from "../../svg/tabSixth";
import TabSeventh from "../../svg/tabSeventh";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Link } from "react-router-dom";

const widthWindow = document.documentElement.clientWidth <= 768

const BlockLeft = ({ activeDoorAnimation, closeDoorBlock, arrayDataTab, currentTab, params, data, campid, publicKey }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const url = "https://icreate-campaign.com/Admin/WS/InsertLeadCRM";
    const [submitForm, setSubmitForm] = useState(false)


    useEffect(() => {
        setSubmitForm(false)
    }, [currentTab])

    const onSubmit = data => {
        data['camp_id'] = campid
        data['public_key_token'] = publicKey
        delete data['select']
        const AddColomSPA = (formData) => {
            axios({
                method: "post",
                url: url,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            })
        };
        var form_data = new FormData();

        for (var key in data) {
            form_data.append(key, data[key]);
        }
        AddColomSPA(form_data)
        window.gtag('event', 'conversion', { 'send_to': 'AW-695084254/JZ8-CKK9lKICEN7JuMsC' });
        window.fbq('track', 'Lead');
        setSubmitForm(true)
    }

    return (
        <>
            {arrayDataTab.map(({ id, color, titleMain, textBorder1, textBorder2, textBorderMob1, textBorderMob2, textBorderMob3, textBorderMob4, titleText, mainText, titleUnderText,
                textCount, count, }) => {
                return (
                    id === currentTab &&
                    <div key={id + 'arrayDataTab'}
                        className={classNames(styles.blockDoorOne, {
                            [styles.active]: activeDoorAnimation
                        })}>
                        <Link onClick={() => { closeDoorBlock() }} to={{ pathname: "/", search: window.location.search }} className={styles.close}>
                            <p>חזור<br />
                                לדף הבית</p>
                        </Link>
                        <div className={styles.blockDoorOneContent}>
                            <div className={styles.parent}>
                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="330.232" height="44.303" viewBox="0 0 330.232 44.303">
                                    <g>
                                        <g transform="translate(149.654 0)">
                                            <path d="M-20.247,0V23.215c0,6.025-3.1,8.418-7.621,8.418h-3.721V0h-13.2V31.633H-55.6V0H-68.715V44.3h42.354C-16.083,44.3-7.4,36.772-7.4,25.873V0ZM-1.02,44.3,49.4,44.215V17.1C49.4,6.646,43.106,0,32.118,0H20.068V12.582H30.435c4.873,0,6.025,2.393,6.025,7.621V31.544H11.916V0L-1.02.089Zm55.822,0H68.092V0H54.8ZM73.851,0V12.494H98.838V44.3h13.026V0Z" transform="translate(68.715)" fill="#fff" />
                                        </g>
                                        <g >
                                            <path d="M0-18.508,50.151-18.6V-46.153c0-10.456-6.557-16.658-17.367-16.658H0Zm13.114-12.76V-50.229h17.9c4.7,0,6.025,1.595,6.025,6.646v12.316Zm42.353-5.67H69.112V-62.811H55.467Zm19.138,0H88.251V-62.811H74.6Zm19.4,18.43,50.417-.089V-45.71c0-10.456-6.291-17.1-17.278-17.1H115.1v12.582h10.367c4.873,0,6.025,2.393,6.025,7.621v11.341H106.946V-62.811l-12.937.088Z" transform="translate(0 62.811)" fill={color} />
                                        </g>
                                        <g transform="translate(21.124 19.828)">
                                            <path d="M-1.4-2.795A3.345,3.345,0,0,0,1.948-6.14,3.345,3.345,0,0,0-1.4-9.485,3.345,3.345,0,0,0-4.743-6.14,3.345,3.345,0,0,0-1.4-2.795" transform="translate(4.743 9.485)" fill="#fff" />
                                        </g>
                                    </g>
                                    <g transform="translate(-9.869 59.504)">
                                        <path className="clockMinutes" d="M32.794-36.354H35.47V-49.4H32.794Z" transform-origin="34.6px -37px" fill="#fff" />
                                    </g>
                                    <g transform="translate(24.131 25.504)">
                                        <path className="clockSeconds" d="M0-.763l15.284,16.39L17.241,13.8,1.957-2.588Z" transform-origin=".4px -2px" fill="#fff" />
                                    </g>
                                </svg>
                            </div>
                            <p className={styles.ContentTitle}>{titleMain}</p>
                            {widthWindow &&
                                <div className={styles.CountBlockMob}>
                                    <div className={styles.rtl} style={{ color: color }}>
                                        {!textBorderMob3 && <span className={styles.oldplus}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24.605" height="24.604" viewBox="0 0 24.605 24.604">
                                                <path d="M6.75,20.6V13.855H0V6.75H6.75V0h7.105V6.75H20.6v7.105h-6.75V20.6Z" transform="translate(2 2)" fill={color} stroke="#232323" stroke-width="4" />
                                            </svg>
                                        </span>}
                                        {textBorderMob1 &&
                                            <p style={{ border: `3px solid ${color}` }}><span>{textBorderMob1}</span></p>
                                        }
                                        {textBorderMob2 &&
                                            <p style={{ border: `3px solid ${color}`, borderLeft: '0px' }}><span>{textBorderMob2}</span></p>
                                        }
                                        {textBorderMob3 &&
                                            <div className={styles.cont}>
                                                <span className={styles.plus}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                        <path d="M6.75,20.6V13.855H0V6.75H6.75V0h7.105V6.75H20.6v7.105h-6.75V20.6Z" transform="translate(2 2)" fill={color} stroke="#232323" stroke-width="4" /> */}
                                                    </svg>
                                                </span>

                                                {textBorderMob3 &&
                                                    <p style={{ border: `3px solid ${color}`, borderTop: '0px' }}><span>{textBorderMob3}</span></p>
                                                }
                                                {textBorderMob4 &&
                                                    <p style={{ border: `3px solid ${color}`, borderTop: '0px', borderLeft: '0px' }}><span>{textBorderMob4}</span></p>
                                                }
                                            </div>
                                        }

                                    </div>
                                </div>
                            }
                            {!widthWindow &&
                                <div style={{
                                    borderBottom: `1px solid ${color}`,
                                    borderTop: `1px solid ${color}`
                                }} className={styles.CountBlock}>
                                    <p className={styles.rtl} style={{ color: color }}>
                                        {textBorder1} {id === 3 && <span>+</span>}<br />{textBorder2}
                                    </p>
                                </div>
                            }
                        </div>
                        <div className={styles.blockDoorOneContent}>
                            <div className={styles.blockDes}>
                                <p className={styles.blockDesTitle} dangerouslySetInnerHTML={{ __html: titleText }}>
                                </p>
                                <p className={styles.blockDesText} dangerouslySetInnerHTML={{ __html: mainText }}>
                                </p>
                            </div></div>
                        {currentTab === 0 && !widthWindow &&
                            <div className={styles.flexNewText}>
                                <div className={styles.flexNewItem}>
                                    <div className={styles.flexNewTitle}>דקה</div>
                                    <div className={styles.flexNewDesk}>מכביש <br />החוף</div>
                                </div>
                                <div className={styles.flexNewItem}>
                                    <div className={styles.flexNewTitle}>2 דק׳</div>
                                    <div className={styles.flexNewDesk}>מפארק <br />איינשטיין</div>
                                </div>

                                <div className={styles.flexNewItem}>
                                    <div className={styles.flexNewTitle}>4 דק׳</div>
                                    <div className={styles.flexNewDesk}>ממושב <br />אביחיל</div>
                                </div>

                                <div className={styles.flexNewItem}>
                                    <div className={styles.flexNewTitle}>5 דק׳</div>
                                    <div className={styles.flexNewDesk}>מקניון <br />השרון</div>
                                </div>

                                <div className={styles.flexNewItem}>
                                    <div className={styles.flexNewTitle}>5 דק׳</div>
                                    <div className={styles.flexNewDesk}>מתחנת <br />הרכבת</div>
                                </div>
                            </div>
                        }
                        <div className={styles.blockDoorOneContent}>
                            <div className={styles.blockDes}>
                                {currentTab === 1 &&
                                    <p style={{ color: color, fontWeight: 'bold' }} className={styles.blockDesText}>
                                        האכלוס – מיידי!
                                    </p>
                                }
                                <p className={styles.blockDesSubText}>
                                    {titleUnderText}
                                </p>
                                {currentTab === 0 && widthWindow &&
                                    <div className={styles.flexNewText}>
                                        <div className={styles.flexNewItem}>
                                            <div className={styles.flexNewTitle}>דקה</div>
                                            <div className={styles.flexNewDesk}>מכביש <br />החוף</div>
                                        </div>
                                        <div className={styles.flexNewItem}>
                                            <div className={styles.flexNewTitle}>2 דק׳</div>
                                            <div className={styles.flexNewDesk}>מפארק <br />איינשטיין</div>
                                        </div>

                                        <div className={styles.flexNewItem}>
                                            <div className={styles.flexNewTitle}>4 דק׳</div>
                                            <div className={styles.flexNewDesk}>ממושב <br />אביחיל</div>
                                        </div>

                                        <div className={styles.flexNewItem}>
                                            <div className={styles.flexNewTitle}>5 דק׳</div>
                                            <div className={styles.flexNewDesk}>מקניון <br />השרון</div>
                                        </div>

                                        <div className={styles.flexNewItem}>
                                            <div className={styles.flexNewTitle}>5 דק׳</div>
                                            <div className={styles.flexNewDesk}>מתחנת <br />הרכבת</div>
                                        </div>
                                    </div>
                                }
                                <p className={classNames(styles.blockDesText, styles.adaptive)}>
                                    {textCount}
                                </p>
                                <p style={{ color: color }} className={styles.blockDesCount}>
                                    {count.length > 1 && <><span>₪</span>{count}</>}
                                </p>
                                {currentTab === 3 &&
                                    <p className={classNames(styles.blockDesText, styles.adaptive)}>קומה 11 (רואה ים)</p>
                                }
                                {currentTab === 1 &&
                                    <div className={styles.uniqueСase}>
                                        <div className={styles.borderLeft}>
                                            <p className={styles.blockDesText}>דירת גן –107 מ״ר + 70 מ״ר מרפסת</p>
                                            <p style={{ color: color }} className={styles.blockDesCount}><span>₪</span>4,500,000</p>
                                        </div>
                                    </div>
                                }
                                {currentTab === 6 &&
                                    <div>
                                        <div style={{ gap: "75px" }} className={styles.uniqueСase}>
                                            <div>
                                                <p className={styles.blockDesText} style={{ fontSize: '13px', lineHeight: '23px' }}>דירת 5 חד׳ החל מ-</p>
                                                <p style={{ color: color }}
                                                    className={styles.blockDesCountSmall}><span>₪</span>1,730,000</p>
                                            </div>
                                            <div>
                                                <p className={styles.blockDesText}>דירת 4 חד׳ החל מ-</p>
                                                <p style={{ color: color }}
                                                    className={styles.blockDesCountSmall}><span>₪</span>1,580,000</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {!submitForm ?

                            <form onSubmit={handleSubmit(onSubmit)} className={styles.blockForm}>
                                <p className={styles.blockFormTitle}>
                                    זה הזמן להתקדם לדירה בהטבה! מלאו פרטים ונחזור אליכם בהקדם
                                </p>
                                <p className={styles.blockFormTitleMobile}>
                                    לתיאום פגישה:
                                </p>
                                <div className={styles.wrapperInput}>
                                    <div className={styles.formInput}>
                                        {errors.full_name && <span className={styles.error}>⚠ אנא הזן שם מלא</span>}
                                        <input onKeyPress={(event) => {
                                            if (!/[a-zA-Za-zA-Z\u0590-\u05FF\u200f\u200e ]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} className={classNames(styles.blockFormInput, styles.fullName)} {...register("full_name", { required: true })} type="text" />
                                        <span>שם מלא:</span>
                                    </div>
                                    <div className={styles.formInput}>
                                        {errors.email && <span className={styles.error}>⚠ אנא הזן כתובת מייל חוקית</span>}
                                        <input className={classNames(styles.blockFormInput, styles.email)} {...register("email", { required: true })} type="text" />
                                        <span>מייל:</span>
                                    </div>


                                    <input {...register("media_source")} type="hidden" value={params?.utm_source ? params?.utm_source : ''} />
                                    <input {...register("media_channel")} type="hidden" value={params?.utm_source ? params?.utm_source : ''} />
                                    <input {...register("utm_source")} type="hidden" value={params?.utm_source ? params?.utm_source : ''} />
                                    <input {...register("utm_medium")} type="hidden" value={params?.utm_medium ? params?.utm_medium : ''} />
                                    <input {...register("utm_campaign")} type="hidden" value={params?.utm_campaign ? params?.utm_campaign : ''} />
                                    <input {...register("utm_term")} type="hidden" value={params?.utm_term ? params?.utm_term : ''} />
                                    <input {...register("utm_content")} type="hidden" value={params?.utm_content ? params?.utm_content : ''} />

                                </div>
                                <div className={styles.wrapperButton}>
                                    <div className={styles.formInputTwo}>
                                        {errors.phone && <span className={styles.error}>⚠ אנא הזן מספר פלאפון תקין</span>}
                                        <input onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} className={classNames(styles.blockFormInput, styles.optOut)} {...register("phone", { required: true })} type="text" />
                                        <span>טלפון:</span>
                                    </div>
                                    <div className={styles.wrapperButtonFix}>
                                        <div>
                                            <input id="checkbox" {...register("email_opt_out", { required: false })} type="checkbox" />
                                            <label htmlFor="checkbox" className={styles.checkboxTitle}>אני מאשר/ת קבלת מידע שיווקי</label>
                                        </div>
                                        <button className={styles.blockFormButton}>שלח</button>
                                    </div>
                                </div>

                            </form>
                            :
                            <div className={styles.succsessSubmit}>
                                <p>
                                    <span>תודה,</span><br /><span>פרטיך התקבלו</span><br /><span>בהצלחה!</span>
                                </p>
                            </div>}
                        <div className={styles.iconFirms}>
                            <MainSvgTab />
                            {id === 0 ? <TabOne /> :
                                id === 1 ? <TabSecond /> :
                                    id === 2 ? <TabThird /> :
                                        id === 3 ? <TabFourth /> :
                                            id === 4 ? <TabFifth /> :
                                                id === 5 ? <TabSixth /> :
                                                    <TabSeventh />}
                        </div>
                        <p className={classNames(styles.formBottomTextDesk)}>
                            ההטבות לחוזים שיחתמו עד ה- 31.12.21 בלבד!
                        </p>
                        <p className={styles.formBottomText}>
                            **ההטבות משתנות מפרויקט לפרויקט *בכפוף לתקנון *מוגבל למספר דירות בכל פרויקט *לחותמים על חוזה עד ה- 31.12.2021 בלבד *ההדמיות להמחשה בלבד. ט.ל.ח.
                        </p>
                    </div>

                )
            })}
        </>

    );
};

export default BlockLeft;