import React from 'react';

const HeaderLogo = () => {
    return (
        <svg xmlnsXlink="http://www.w3.org/1999/xlink" width="98.361" height="20.575" viewBox="0 0 98.361 20.575">
 
            <g >
                <g >
                <path d="M97.435,4.249a4.114,4.114,0,1,0-4.114,4.14,4.127,4.127,0,0,0,4.114-4.14m-4.114,8.384-4.994,7.576h9.988ZM79.051,20.208h6.415V.109H79.051Zm-8.34-8.245h2.944V.3H64.29V3.124h6.421Zm-6.232,6.406-1.44-2.082H62.5l2.688,3.8H62.57V20.5h3.081v-.446l-.886-1.281L66.137,16.3l-.511-.009Zm-4.961-6.406h2.991V.3H59.518ZM58.487,20.5h.443V16.288h-.443ZM45.26,11.963l12.471-.024V4.548A3.975,3.975,0,0,0,53.4.3H50.453V3.148H52.91c1.413,0,1.9.538,1.9,1.936V9.095H48.2V.3L45.26.326Zm.245,4.325h-.443V20.5H45.5ZM43.43,7.252V.3H40.486V6.74a2.12,2.12,0,0,1-2.318,2.379h-.556V.3H34.621V9.119H31.863V.3H28.874V11.963h9.735A4.6,4.6,0,0,0,43.43,7.252M30.652,16.288H27.636V16.7H30.2v3.387H27.644V20.5h3.467v-.412h-.459ZM14.572,11.963l12.47-.024V4.548A3.975,3.975,0,0,0,22.707.3H19.764V3.148H22.22c1.415,0,1.9.538,1.9,1.936V9.095h-6.6V.3L14.572.326ZM9.657,7.043H12.74V.3H9.657Zm-4.821,0H7.919V.3H4.836ZM3.274,20.5h.443V16.288H.477V16.7h2.8ZM.109,13.985H3.029V.3H.109ZM.963,17.94H.52V20.5H.963ZM7.79,16.288H7.338v2.266H7.79Zm4.082,0H11.42v2.266h.452ZM15.939,20.1h-.587v.4h.587a1.193,1.193,0,0,0,1.23-1.313v-2.9H15.462v.4h1.272V19.22a.79.79,0,0,1-.795.876m7.74-3.808H20.665V16.7h2.562v3.387H20.674V20.5H24.14v-.412h-.461Zm13.984,3.867v.421h.058a1.067,1.067,0,0,0,1.147-1.2V16.7h2.152v3.8h.435V16.288H37.764V16.7h.661v2.679c0,.532-.236.767-.762.776M49.611,20.1h-.585v.4h.585a1.194,1.194,0,0,0,1.231-1.313v-2.9H49.134v.4h1.273V19.22a.791.791,0,0,1-.8.876m5.229-3.808h-.452v2.266h.452Zm17.129,0H69.414v.4h.512V20.5h.443V16.693h1.608a.845.845,0,0,1,.938.926V20.1H71.173v.4h2.169V17.661a1.245,1.245,0,0,0-1.372-1.374" transform="translate(-0.054 -0.055)" fill="#fff"/>
                <path data-name="Контур 145" d="M97.435,4.249a4.114,4.114,0,1,0-4.114,4.14,4.127,4.127,0,0,0,4.114-4.14m-4.114,8.384-4.994,7.576h9.988ZM79.051,20.208h6.415V.109H79.051Zm-8.34-8.245h2.944V.3H64.29V3.124h6.421Zm-6.232,6.406-1.44-2.082H62.5l2.688,3.8H62.57V20.5h3.081v-.446l-.886-1.281L66.137,16.3l-.511-.009Zm-4.961-6.406h2.991V.3H59.518ZM58.487,20.5h.443V16.288h-.443ZM45.26,11.963l12.471-.024V4.548A3.975,3.975,0,0,0,53.4.3H50.453V3.148H52.91c1.413,0,1.9.538,1.9,1.936V9.095H48.2V.3L45.26.326Zm.245,4.325h-.443V20.5H45.5ZM43.43,7.252V.3H40.486V6.74a2.12,2.12,0,0,1-2.318,2.379h-.556V.3H34.621V9.119H31.863V.3H28.874V11.963h9.735A4.6,4.6,0,0,0,43.43,7.252M30.652,16.288H27.636V16.7H30.2v3.387H27.644V20.5h3.467v-.412h-.459ZM14.572,11.963l12.47-.024V4.548A3.975,3.975,0,0,0,22.707.3H19.764V3.148H22.22c1.415,0,1.9.538,1.9,1.936V9.095h-6.6V.3L14.572.326ZM9.657,7.043H12.74V.3H9.657Zm-4.821,0H7.919V.3H4.836ZM3.274,20.5h.443V16.288H.477V16.7h2.8ZM.109,13.985H3.029V.3H.109ZM.963,17.94H.52V20.5H.963ZM7.79,16.288H7.338v2.266H7.79Zm4.082,0H11.42v2.266h.452ZM15.939,20.1h-.587v.4h.587a1.193,1.193,0,0,0,1.23-1.313v-2.9H15.462v.4h1.272V19.22a.79.79,0,0,1-.795.876m7.74-3.808H20.665V16.7h2.562v3.387H20.674V20.5H24.14v-.412h-.461Zm13.984,3.867v.421h.058a1.067,1.067,0,0,0,1.147-1.2V16.7h2.152v3.8h.435V16.288H37.764V16.7h.661v2.679c0,.532-.236.767-.762.776M49.611,20.1h-.585v.4h.585a1.194,1.194,0,0,0,1.231-1.313v-2.9H49.134v.4h1.273V19.22a.791.791,0,0,1-.8.876m5.229-3.808h-.452v2.266h.452Zm17.129,0H69.414v.4h.512V20.5h.443V16.693h1.608a.845.845,0,0,1,.938.926V20.1H71.173v.4h2.169V17.661a1.245,1.245,0,0,0-1.372-1.374" transform="translate(-0.054 -0.055)" fill="none" stroke="#f5f6f7" strokeWidth="0.217"/>
                </g>
            </g>
            </svg>
    );
};

export default HeaderLogo;