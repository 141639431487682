import React from 'react';
import classNames from 'classnames'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from "./index.module.scss";
import { Link } from "react-router-dom";

const widthWindow = document.documentElement.clientWidth >= 768

const LinkItem = ({ title, route, text, color, subTitle, href, id, OpenItem, changePhoto, transition, currentTab, setCurrentTab, photo, isHeader = false }) => {


    return (
        <>
            <Link to={{ pathname: route, search: window.location.search }}
                onClick={() => { setCurrentTab(id); OpenItem(); }}
                className={classNames(styles.BlockLink, {
                    [styles.active]: changePhoto,
                    [styles.transition]: transition,
                    [styles.currentTab]: id === currentTab,
                    [styles.inHeader]: isHeader
                })}>
                <div className={styles.BlockLinkPhoto}>
                    {widthWindow &&

                        <>{id === 3 ?
                            <svg xmlns="http://www.w3.org/2000/svg"  width="35.929" height="46.51" viewBox="0 0 35.929 46.51">
                                <defs>
                                    <clipPath id="clipPath">
                                        <path id="Контур_147" data-name="Контур 147" d="M0,0H35.929V-46.51H0Z" fill="none" />
                                    </clipPath>
                                </defs>
                                <g id="Сгруппировать_111" data-name="Сгруппировать 111" transform="translate(0 46.51)">
                                    <g id="Сгруппировать_97" data-name="Сгруппировать 97" clipPath="url(#clipPath)">
                                        <g id="Сгруппировать_96" data-name="Сгруппировать 96" transform="translate(3.739 -33.54)">
                                            <path id="Контур_146" data-name="Контур 146" d="M0,0H-2.872V-12.464H.018c2.565,0,3.847,1.247,3.847,3.107a2.842,2.842,0,0,1-1.9,2.728A2.987,2.987,0,0,1,4.425-3.6C4.425-1.21,2.673,0,0,0M-.018-11.38H-1.644v4.335h1.59C1.716-7.045,2.637-8,2.637-9.3S1.77-11.38-.018-11.38M.036-6.015h-1.68v4.931H.108c1.9,0,3.089-.885,3.089-2.438,0-1.7-1.21-2.493-3.161-2.493" fill="#002b51" />
                                        </g>
                                    </g>
                                    <g id="Сгруппировать_98" data-name="Сгруппировать 98" transform="translate(10.694 -46.509)">
                                        <path id="Контур_148" data-name="Контур 148" d="M0,0-1.174.217V12.969H0Z" fill="#002b51" />
                                    </g>
                                    <g id="Сгруппировать_102" data-name="Сгруппировать 102" clipPath="url(#clipPath)">
                                        <g id="Сгруппировать_99" data-name="Сгруппировать 99" transform="translate(18.28 -33.54)">
                                            <path id="Контур_149" data-name="Контур 149" d="M0,0-.2-1.264A4.7,4.7,0,0,1-3.269.181a2.326,2.326,0,0,1-2.547-2.62v-6.1h1.192v5.889c0,1.1.506,1.752,1.589,1.752A4.19,4.19,0,0,0-.271-2.366V-8.544H.921V0Z" fill="#002b51" />
                                        </g>
                                        <g id="Сгруппировать_100" data-name="Сгруппировать 100" transform="translate(27.511 -37.731)">
                                            <path id="Контур_150" data-name="Контур 150" d="M0,0H-5.943c.036,2.1,1.012,3.342,2.728,3.342A4.618,4.618,0,0,0-.2,2.077l.181,1.084a4.968,4.968,0,0,1-3.27,1.21c-2.239,0-3.847-1.517-3.847-4.461,0-2.746,1.59-4.444,3.685-4.444C-1.12-4.534,0-2.619,0-.253ZM-3.45-3.577c-1.337,0-2.2.994-2.439,2.638h4.661c-.127-1.536-.849-2.638-2.222-2.638" fill="#002b51" />
                                        </g>
                                        <g id="Сгруппировать_101" data-name="Сгруппировать 101" transform="translate(10.043 -18.288)">
                                            <path id="Контур_151" data-name="Контур 151" d="M0,0-2.005-1.987A4.983,4.983,0,0,1-6.087.09c-2.439,0-3.956-1.336-3.956-3.359,0-1.879,1.246-3.017,2.962-3.812a3.812,3.812,0,0,1-1.3-2.8A2.945,2.945,0,0,1-5.13-12.77a5.906,5.906,0,0,1,2.944.776l-.234,1.247a5.267,5.267,0,0,0-2.746-.94A1.754,1.754,0,0,0-7.135-9.935,3.388,3.388,0,0,0-5.8-7.478l3.648,3.7A12.032,12.032,0,0,0-1.138-7.37L0-7.135A12.914,12.914,0,0,1-1.355-2.98L1.283-.307ZM-6.322-6.322c-1.553.65-2.457,1.662-2.457,2.944a2.476,2.476,0,0,0,2.71,2.4,4.031,4.031,0,0,0,3.3-1.788Z" fill="#fb3099" />
                                        </g>
                                    </g>
                                    <g id="Сгруппировать_103" data-name="Сгруппировать 103" transform="translate(16.366 -29.74)">
                                        <path id="Контур_153" data-name="Контур 153" d="M0,0V11.344H-1.228V0H-5.274V-1.12H3.9L4.082,0Z" fill="#fb3099" />
                                    </g>
                                    <g id="Сгруппировать_110" data-name="Сгруппировать 110" clipPath="url(#clipPath)">
                                        <g id="Сгруппировать_104" data-name="Сгруппировать 104" transform="translate(26.5 -18.396)">
                                            <path id="Контур_154" data-name="Контур 154" d="M0,0V-5.889c0-1.1-.506-1.77-1.59-1.77A4.223,4.223,0,0,0-4.353-6.178V0H-5.545V-12.753l1.192-.216v5.635a4.6,4.6,0,0,1,3-1.39,2.32,2.32,0,0,1,2.547,2.6V0Z" fill="#fb3099" />
                                        </g>
                                        <g id="Сгруппировать_105" data-name="Сгруппировать 105" transform="translate(35.929 -22.586)">
                                            <path id="Контур_155" data-name="Контур 155" d="M0,0H-5.943c.036,2.1,1.012,3.342,2.728,3.342A4.618,4.618,0,0,0-.2,2.077l.181,1.084a4.973,4.973,0,0,1-3.27,1.21c-2.239,0-3.847-1.517-3.847-4.461,0-2.746,1.59-4.444,3.685-4.444C-1.12-4.534,0-2.619,0-.253ZM-3.45-3.577c-1.337,0-2.2.994-2.439,2.638h4.661c-.127-1.536-.849-2.638-2.222-2.638" fill="#fb3099" />
                                        </g>
                                        <g id="Сгруппировать_106" data-name="Сгруппировать 106" transform="translate(5.961 -3.016)">
                                            <path id="Контур_156" data-name="Контур 156" d="M0,0C-3.378,0-5.654-2.6-5.654-6.449c0-3.829,2.312-6.484,5.744-6.484A6.019,6.019,0,0,1,3.6-11.867L3.4-10.6a5.621,5.621,0,0,0-3.324-1.21c-2.745,0-4.443,2.167-4.443,5.328,0,3.2,1.716,5.347,4.425,5.347a5.846,5.846,0,0,0,3.559-1.3l.18,1.193A6.479,6.479,0,0,1,0,0" fill="#fb3099" />
                                        </g>
                                        <g id="Сгруппировать_107" data-name="Сгруппировать 107" transform="translate(11.778 -13.836)">
                                            <path id="Контур_157" data-name="Контур 157" d="M0,0A.879.879,0,0,1-.867-.867.879.879,0,0,1,0-1.734a.863.863,0,0,1,.849.867A.864.864,0,0,1,0,0M-.6,2.041H.578v8.544H-.6Zm0,8.544" fill="#fb3099" />
                                        </g>
                                        <g id="Сгруппировать_108" data-name="Сгруппировать 108" transform="translate(16.835 -3.107)">
                                            <path id="Контур_158" data-name="Контур 158" d="M0,0C-1.337,0-2.222-.524-2.222-2.1V-7.677H-3.54V-8.688h1.318v-2.963l1.174-.2v3.161H.921l.163,1.011H-1.048v5.383c0,.867.343,1.246,1.265,1.246a4.853,4.853,0,0,0,.795-.09l.18.993A4.579,4.579,0,0,1,0,0" fill="#fb3099" />
                                        </g>
                                        <g id="Сгруппировать_109" data-name="Сгруппировать 109" transform="translate(22.056 -2.186)">
                                            <path id="Контур_159" data-name="Контур 159" d="M0,0C-.614,1.662-1.373,2.186-2.637,2.186a2.953,2.953,0,0,1-.47-.055l-.162-1.047a3.681,3.681,0,0,0,.7.072c.705,0,1.12-.307,1.445-1.138l.4-1.03L-3.956-9.5-2.8-9.718-.145-2.439,2.384-9.61H3.613Z" fill="#fb3099" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg"  width="50" height="47" viewBox="0 0 50 47">
                                <image id="firs_logo" data-name="firs logo" width="50" height="47" href={href} />
                            </svg>
                        }</>
                    }
                </div>
                <div className={styles.BlockLinkWrapper}>
                    <div style={{ background: color }}
                        className={classNames(styles.BlockLinkText)}>
                        <p className="project">{title}<br />{subTitle}</p>
                        <span className={styles.BlockLinkSub}>{text}</span>
                    </div>
                    {widthWindow &&
                        <LazyLoadImage
                            alt="image"
                            height="94px"
                            effect="blur"
                            src={photo}
                            width="165px" />
                    }
                </div>
            </Link>

        </>
    );
};

export default LinkItem;