import React from 'react';

const MainSvgTab = () => {
    return (
        <div className="logoFlex" >
            <svg xmlns="http://www.w3.org/2000/svg" width="196.721" height="41.151"
                viewBox="0 0 196.721 41.151">
                <defs>
                    <clipPath id="clipPath2">
                        <rect id="Прямоугольник_70" data-name="Прямоугольник 70" width="196.721"
                            height="41.151" transform="translate(0 0)" fill="none" />
                    </clipPath>
                </defs>
                <g id="ROTSHTEIN_LOGO" data-name="ROTSHTEIN LOGO" transform="translate(0 0)">
                    <g id="Сгруппировать_95" data-name="Сгруппировать 95">
                        <g id="Сгруппировать_94" data-name="Сгруппировать 94"
                            clipPath="url(#clipPath2)">
                            <path id="Контур_144" data-name="Контур 144"
                                d="M194.761,8.388a8.228,8.228,0,1,0-8.229,8.28,8.253,8.253,0,0,0,8.229-8.28m-8.229,16.767-9.988,15.152h19.976ZM157.993,40.307h12.83V.109h-12.83Zm-16.681-16.49H147.2V.5h-18.73V6.139h12.841ZM128.849,36.629l-2.88-4.163H124.9l5.376,7.6h-5.241v.825h6.163V40l-1.773-2.562,2.745-4.953-1.021-.017Zm-9.923-12.813h5.981V.5h-5.981Zm-2.061,17.073h.886V32.466h-.886ZM90.411,23.816l24.942-.048V8.986c0-5.226-3.292-8.488-8.668-8.488H100.8V6.186h4.914c2.826,0,3.8,1.075,3.8,3.871V18.08H96.3V.5L90.411.542Zm.49,8.65h-.886v8.424H90.9ZM86.75,14.395V.5H80.863V13.37c0,3.265-1.852,4.758-4.636,4.758H75.115V.5H69.133v17.63H63.617V.5H57.639V23.816H77.108c5.379,0,9.643-3.73,9.643-9.422M61.194,32.466H55.163v.824H60.29v6.775H55.18v.825h6.933v-.825h-.919Zm-32.159-8.65,24.94-.048V8.986C53.975,3.76,50.685.5,45.306.5H39.419V6.186h4.914c2.829,0,3.8,1.075,3.8,3.871V18.08H34.924V.5L29.035.542Zm-9.829-9.84h6.166V.5H19.206Zm-9.643,0h6.166V.5H9.563ZM6.439,40.889h.886V32.466H.846v.824H6.439ZM.109,27.861H5.95V.5H.109ZM1.817,35.77H.931v5.123h.886Zm13.655-3.3h-.9V37h.9Zm8.164,0h-.9V37h.9Zm8.133,7.617H30.6v.807h1.174a2.387,2.387,0,0,0,2.461-2.627v-5.8H30.815v.792h2.543v5.072a1.579,1.579,0,0,1-1.59,1.752m15.48-7.617H41.222v.824h5.124v6.775H41.239v.825h6.932v-.825h-.922ZM75.218,40.2v.842h.117a2.134,2.134,0,0,0,2.295-2.409V33.29h4.3v7.6H82.8V32.466H75.419v.824h1.323v5.359c0,1.063-.471,1.534-1.524,1.552m23.9-.118h-1.17v.807h1.17a2.388,2.388,0,0,0,2.462-2.627v-5.8H98.16v.792h2.546v5.072a1.581,1.581,0,0,1-1.593,1.752m10.459-7.617h-.9V37h.9Zm34.257,0h-5.11v.81h1.024v7.614h.886V33.276h3.216a1.69,1.69,0,0,1,1.876,1.852V40.1h-3.485v.792h4.337V35.213a2.49,2.49,0,0,0-2.745-2.747"
                                fill="#fff" />
                            <path id="Контур_145" data-name="Контур 145"
                                d="M194.761,8.388a8.228,8.228,0,1,0-8.229,8.28,8.253,8.253,0,0,0,8.229-8.28m-8.229,16.767-9.988,15.152h19.976ZM157.993,40.307h12.83V.109h-12.83Zm-16.681-16.49H147.2V.5h-18.73V6.139h12.841ZM128.849,36.629l-2.88-4.163H124.9l5.376,7.6h-5.241v.825h6.163V40l-1.773-2.562,2.745-4.953-1.021-.017Zm-9.923-12.813h5.981V.5h-5.981Zm-2.061,17.073h.886V32.466h-.886ZM90.411,23.816l24.942-.048V8.986c0-5.226-3.292-8.488-8.668-8.488H100.8V6.186h4.914c2.826,0,3.8,1.075,3.8,3.871V18.08H96.3V.5L90.411.542Zm.49,8.65h-.886v8.424H90.9ZM86.75,14.395V.5H80.863V13.37c0,3.265-1.852,4.758-4.636,4.758H75.115V.5H69.133v17.63H63.617V.5H57.639V23.816H77.108c5.379,0,9.643-3.73,9.643-9.422M61.194,32.466H55.163v.824H60.29v6.775H55.18v.825h6.933v-.825h-.919Zm-32.159-8.65,24.94-.048V8.986C53.975,3.76,50.685.5,45.306.5H39.419V6.186h4.914c2.829,0,3.8,1.075,3.8,3.871V18.08H34.924V.5L29.035.542Zm-9.829-9.84h6.166V.5H19.206Zm-9.643,0h6.166V.5H9.563ZM6.439,40.889h.886V32.466H.846v.824H6.439ZM.109,27.861H5.95V.5H.109ZM1.817,35.77H.931v5.123h.886Zm13.655-3.3h-.9V37h.9Zm8.164,0h-.9V37h.9Zm8.133,7.617H30.6v.807h1.174a2.387,2.387,0,0,0,2.461-2.627v-5.8H30.815v.792h2.543v5.072a1.579,1.579,0,0,1-1.59,1.752m15.48-7.617H41.222v.824h5.124v6.775H41.239v.825h6.932v-.825h-.922ZM75.218,40.2v.842h.117a2.134,2.134,0,0,0,2.295-2.409V33.29h4.3v7.6H82.8V32.466H75.419v.824h1.323v5.359c0,1.063-.471,1.534-1.524,1.552m23.9-.118h-1.17v.807h1.17a2.388,2.388,0,0,0,2.462-2.627v-5.8H98.16v.792h2.546v5.072a1.581,1.581,0,0,1-1.593,1.752m10.459-7.617h-.9V37h.9Zm34.257,0h-5.11v.81h1.024v7.614h.886V33.276h3.216a1.69,1.69,0,0,1,1.876,1.852V40.1h-3.485v.792h4.337V35.213a2.49,2.49,0,0,0-2.745-2.747"
                                fill="none" stroke="#f5f6f7" strokeWidth="0.217" />
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default MainSvgTab;