import React from 'react';

const TabOne = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="154.585" height="55.399" viewBox="0 0 154.585 55.399">
                <g id="LOGO" transform="translate(-1232.357 -608.907)">
                    <path id="Контур_193" data-name="Контур 193" d="M1234.737,619.16h-1.043v-9.247h-.924v-.979h1.967Z" transform="translate(-0.179 -0.012)" fill="#fff"/>
                    <path id="Контур_194" data-name="Контур 194" d="M1241.318,613.839h-1.043v-3.926h-.867v-.98h1.91Z" transform="translate(-3.053 -0.011)" fill="#fff"/>
                    <path id="Контур_195" data-name="Контур 195" d="M1247.657,613.839h-1.043v-3.926h-.867v-.98h1.91Z" transform="translate(-5.798 -0.011)" fill="#fff"/>
                    <path id="Контур_196" data-name="Контур 196" d="M1257.7,615.343q0,2.065-2.149,2.065h-.677q-2.05,0-2.051-1.79v-6.71h1.036v6.625q0,.924.839.923h1.086q.881,0,.881-.937v-5.772h-.063l-1.4,1.078h-.162v-.994l.867-.649a1.326,1.326,0,0,1,.853-.275h.937Z" transform="translate(-8.861 0)" fill="#fff"/>
                    <path id="Контур_197" data-name="Контур 197" d="M1271.454,614.226a3.187,3.187,0,0,1-.818,2.354,3.048,3.048,0,0,1-2.255.81h-3.045v-8.457h1.036V614.2h.691q.825,0,.825-.909v-4.355h1.015v4.158q0,1.89-1.748,1.889h-.782v1.459h2.227q1.818,0,1.819-1.995v-5.511h1.036Z" transform="translate(-14.282 -0.011)" fill="#fff"/>
                    <path id="Контур_198" data-name="Контур 198" d="M1284.919,615.343q0,2.065-2.15,2.065h-.676q-2.051,0-2.051-1.79v-6.71h1.036v6.625q0,.924.839.923H1283q.881,0,.881-.937v-5.772h-.063l-1.4,1.078h-.162v-.994l.867-.649a1.326,1.326,0,0,1,.853-.275h.937Z" transform="translate(-20.649 0)" fill="#fff"/>
                    <path id="Контур_199" data-name="Контур 199" d="M1293.735,617.391h-1.043v-7.478h-.867v-.98h1.91Z" transform="translate(-25.752 -0.012)" fill="#fff"/>
                    <g id="Сгруппировать_130" data-name="Сгруппировать 130" transform="translate(1269.879 608.922)">
                        <path id="Контур_200" data-name="Контур 200" d="M1302.836,617.391H1301.8v-6.858q0-.62-.564-.62h-2.7v-.98h2.622q1.678,0,1.678,1.747Z" transform="translate(-1298.537 -608.934)" fill="#fff"/>
                    </g>
                    <path id="Контур_201" data-name="Контур 201" d="M1384.581,698.809h-1.036v-9.247h-.917v-.979h1.954Z" transform="translate(-65.073 -34.503)" fill="#fff"/>
                    <path id="Контур_202" data-name="Контур 202" d="M1389.829,697.04h-1.036v-7.478h-.861v-.98h1.9Z" transform="translate(-67.37 -34.503)" fill="#fff"/>
                    <g id="Сгруппировать_131" data-name="Сгруппировать 131" transform="translate(1323.828 654.08)">
                        <path id="Контур_203" data-name="Контур 203" d="M1398.2,695.412a2.05,2.05,0,0,1-.1.666,1.44,1.44,0,0,1-.3.515,1.306,1.306,0,0,1-.483.331,1.76,1.76,0,0,1-.665.116h-2.955v-.951h2.927a.5.5,0,0,0,.56-.578v-5.963h-2.423v2.988h1.149v.881h-2.163v-4.835h4.453Z" transform="translate(-1393.693 -688.582)" fill="#fff"/>
                        <path id="Контур_204" data-name="Контур 204" d="M1408.033,691.514a2.489,2.489,0,0,1-.21,1.093,2.673,2.673,0,0,1-.8.881l-.4.331.777,1.663a3.412,3.412,0,0,1,.385,1.128v.43h-4.229v-.951h3.179v-.028a7.272,7.272,0,0,1-.931-1.72l-2.451-5.758h1.078l1.9,4.546a1.782,1.782,0,0,0,.644-1.339v-3.207h1.051Z" transform="translate(-1397.877 -688.582)" fill="#fff"/>
                    </g>
                    <path id="Контур_205" data-name="Контур 205" d="M1419.666,697.039h-1.029v-5.209h1.029Zm3.557,0h-1.029v-7.478h-3.746v-.98h4.775Z" transform="translate(-80.585 -34.502)" fill="#fff"/>
                    <path id="Контур_206" data-name="Контур 206" d="M1430.6,693.487h-1.036v-3.926h-.861v-.98h1.9Z" transform="translate(-85.026 -34.502)" fill="#fff"/>
                    <g id="Сгруппировать_132" data-name="Сгруппировать 132" transform="translate(1346.398 654.08)">
                        <path id="Контур_207" data-name="Контур 207" d="M1436.128,695.552a1.3,1.3,0,0,1-1.464,1.487H1433.5v-.951h1.12q.469,0,.47-.564v-5.963h-1.2v-.98h2.241Z" transform="translate(-1433.502 -688.582)" fill="#fff"/>
                        <path id="Контур_208" data-name="Контур 208" d="M1445.009,697.039h-1.036v-7.478h-2.381v6.032a1.84,1.84,0,0,1-.087.582,1.324,1.324,0,0,1-.256.458,1.109,1.109,0,0,1-.42.3,1.483,1.483,0,0,1-.581.106h-.6v-.951h.56a.329.329,0,0,0,.371-.374v-7.132h4.432Z" transform="translate(-1436.162 -688.582)" fill="#fff"/>
                        <path id="Контур_209" data-name="Контур 209" d="M1453.369,695.552a1.3,1.3,0,0,1-1.463,1.487h-1.162v-.951h1.12q.469,0,.469-.564v-5.963h-1.2v-.98h2.24Z" transform="translate(-1440.968 -688.582)" fill="#fff"/>
                    </g>
                    <path id="Контур_210" data-name="Контур 210" d="M1240.734,664.208h-6.2V636.7h-2.177v-4.732h8.376Z" transform="translate(0 -9.987)" fill="#fff"/>
                    <path id="Контур_211" data-name="Контур 211" d="M1261.546,647.389h-6.221V636.7h-2.2v-4.733h8.421Z" transform="translate(-8.994 -9.987)" fill="#fff"/>
                    <path id="Контур_212" data-name="Контур 212" d="M1282.431,647.389h-6.221V636.7h-2.2v-4.733h8.421Z" transform="translate(-18.038 -9.987)" fill="#fff"/>
                    <g id="Сгруппировать_133" data-name="Сгруппировать 133" transform="translate(1268.081 621.983)">
                        <path id="Контур_213" data-name="Контур 213" d="M1313.141,652.633a5.453,5.453,0,0,1-2.266,4.733,10.012,10.012,0,0,1-5.732,1.444h-2.333q-7.444,0-7.443-6.266V631.97h6.132v21.241q0,1.133,1.089,1.133h3.088q1.288,0,1.289-1.178V636.48a1.23,1.23,0,0,0-.556.088l-2.31,1.534h-.556v-4.644l1.6-.955a4.346,4.346,0,0,1,2.266-.533h5.732Z" transform="translate(-1295.367 -631.97)" fill="#fff"/>
                        <path id="Контур_214" data-name="Контур 214" d="M1356.887,649.722q0,8.911-9.2,8.91H1333.18V631.97h6v15.242H1341q1.133,0,1.133-1.422V631.97h5.777v14.153q0,4.688-4.955,4.688h-3.777v3.222h8.777q2.909,0,2.91-3.133V631.97h6.021Z" transform="translate(-1311.742 -631.97)" fill="#fff"/>
                        <path id="Контур_215" data-name="Контур 215" d="M1392.2,653.588a7.006,7.006,0,0,1-.311,2.311,4.528,4.528,0,0,1-4.51,2.733H1382.2v-4.643h2.6q1.267,0,1.267-1.178V636.7h-3.288V631.97h9.421Z" transform="translate(-1332.97 -631.97)" fill="#fff"/>
                    </g>
                    <path id="Контур_216" data-name="Контур 216" d="M1412.014,637.267h-6.221v-28.36h6.221Z" transform="translate(-75.105)" fill="#ee5f5a"/>
                    <path id="Контур_217" data-name="Контур 217" d="M1428.983,637.267h-6.221v-28.36h6.221Z" transform="translate(-82.453)" fill="#ee5f5a"/>
                    <g id="Сгруппировать_134" data-name="Сгруппировать 134" transform="translate(1349.351 621.983)">
                        <path id="Контур_218" data-name="Контур 218" d="M1457.862,658.632h-6.51l-6.421-14.153c-.134-.074-.193-.059-.177.045a8.432,8.432,0,0,1,.355,2.821v11.287h-5.954V646.856a4.251,4.251,0,0,1,.178-1.333,13.92,13.92,0,0,1,3.555-4.133l-4.177-9.065v-.355h6.554l6.176,13.464c.1,0,.148-.029.134-.089a6.791,6.791,0,0,1-.533-2.955V631.97h6v11a3.249,3.249,0,0,1-.355,1.467,16.321,16.321,0,0,1-3.422,4l4.6,9.887Z" transform="translate(-1438.71 -631.97)" fill="#fff"/>
                    </g>
                    <path id="Контур_219" data-name="Контур 219" d="M1492.71,658.631H1476.6v-4.643h8.044V636.7h-7.776v-4.732h13.93v22.018h1.911Z" transform="translate(-105.768 -9.987)" fill="#fff"/>
                </g>
            </svg>
        </div>
    );
};

export default TabOne;