import './App.css';
import HomePage from "./Components/HomePage/homePage";
import {BrowserRouter} from "react-router-dom";

function App() {
  return (
    <>
        <BrowserRouter>
            <HomePage/>
        </BrowserRouter>
    </>
  );
}

export default App;
