import React, { useState } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'
import HeaderLogo from '../../svg/headerLogo'
import { Link } from "react-router-dom";

const Header = ({ cookie, children, setActiveDoor, closeDoorBlock }) => {
    const [isMenuOpen, setMenuState] = useState(false)
    const [isClosing, setClosing] = useState(false)

    const toggleMenu = () => {
        if (!isMenuOpen) {
            setMenuState(true)
        } else {
            setClosing(true)
            setTimeout(() => {
                setMenuState(false)
                setClosing(false)
            }, 500)
        }
    }

    const closeFromLink = (e) => {
        if (e.target.classList[0] === 'project')
            toggleMenu()
    }




    return (
        <header className={classNames(styles.header, {
            [styles.cookieSuccsesHeader]: cookie,
        })}>
            <Link onClick={() => { closeDoorBlock() }} to={{ pathname: "/", search: window.location.search }} >
                <HeaderLogo />
            </Link>


            <div className={classNames(styles.menuOverlay, {
                [styles.menuOverlayOpen]: isMenuOpen,
                [styles.menuOverlayClosing]: isClosing,
            })}>
                <div className={styles.tWrapp} onClick={toggleMenu}>
                    <button className={styles.toggler}  >
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
                <Link onClick={() => { toggleMenu(); setActiveDoor() }} className={styles.navButton} to={{ pathname: "/", search: window.location.search }} >לדף הבית {`>>`}</Link>
                <div onClick={closeFromLink} className={styles.list}>
                    {children}
                </div>
                <Link onClick={() => { toggleMenu(); setActiveDoor() }} className={styles.menuLogo} to={{ pathname: "/", search: window.location.search }} >
                    <HeaderLogo />
                </Link>
            </div>
        </header>
    )
}


export default Header;