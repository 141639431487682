import React from 'react';

const TabThird = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="70.31" height="100" viewBox="0 0 79.062 112.447">
                <g id="LOGO" transform="translate(-1015.719 -1260.376)">
                    <g id="Сгруппировать_135" data-name="Сгруппировать 135" transform="translate(1039.035 1366.237)">
                        <path id="Контур_220" data-name="Контур 220" d="M1095.9,1623.56h-.85V1617.8h-.85v-.709h1.7Z" transform="translate(-1094.201 -1616.974)" fill="#f7f7e8" />
                        <path id="Контур_221" data-name="Контур 221" d="M1105.126,1619.761h-.85V1617.8h-.85v-.709h1.7Z" transform="translate(-1100.685 -1616.974)" fill="#f7f7e8" />
                        <path id="Контур_222" data-name="Контур 222" d="M1114.191,1619.761h-.851V1617.8h-.85v-.709h1.7Z" transform="translate(-1107.056 -1616.974)" fill="#f7f7e8" />
                        <path id="Контур_223" data-name="Контур 223" d="M1126.994,1618.7a3.23,3.23,0,0,1-.727,2.145,2.5,2.5,0,0,1-2.022.935h-1.437l-.491-4.961h.845l.421,4.252h.548a1.806,1.806,0,0,0,1.521-.718,2.636,2.636,0,0,0,.5-1.654,1.639,1.639,0,0,0-.236-.888.863.863,0,0,0-.766-.425q-.643,0-.822.265v-.737a1.394,1.394,0,0,1,.831-.208,1.71,1.71,0,0,1,1.361.576A2.093,2.093,0,0,1,1126.994,1618.7Z" transform="translate(-1113.964 -1616.706)" fill="#f7f7e8" />
                        <path id="Контур_224" data-name="Контур 224" d="M1146.949,1617.087l-.18,1.776a4.471,4.471,0,0,1-.718,2.164,2.237,2.237,0,0,1-1.937,1.02h-2.1l-.614-4.961h.828l.317,2.409h.368a.546.546,0,0,0,.509-.322,4.366,4.366,0,0,0,.274-1.323q.037-.388.075-.766h.836l-.18,1.626a1.521,1.521,0,0,1-.462.978,1.4,1.4,0,0,1-1,.373h-.349l.139,1.276h1.142q1.823,0,2.049-2.438l.17-1.814Z" transform="translate(-1127.379 -1616.974)" fill="#f7f7e8" />
                        <path id="Контур_225" data-name="Контур 225" d="M1168.566,1618.7a3.23,3.23,0,0,1-.728,2.145,2.5,2.5,0,0,1-2.022.935h-1.437l-.492-4.961h.845l.421,4.252h.548a1.806,1.806,0,0,0,1.521-.718,2.636,2.636,0,0,0,.5-1.654,1.642,1.642,0,0,0-.236-.888.864.864,0,0,0-.766-.425q-.642,0-.822.265v-.737a1.4,1.4,0,0,1,.832-.208,1.711,1.711,0,0,1,1.361.576A2.1,2.1,0,0,1,1168.566,1618.7Z" transform="translate(-1143.185 -1616.706)" fill="#f7f7e8" />
                        <path id="Контур_226" data-name="Контур 226" d="M1184,1622.048h-.85V1617.8h-.851v-.709h1.7Z" transform="translate(-1156.129 -1616.974)" fill="#f7f7e8" />
                        <path id="Контур_227" data-name="Контур 227" d="M1194.82,1622.048h-.851v-3.175q0-1.078-1.144-1.077h-1.615v-.709h1.7a1.989,1.989,0,0,1,1.4.468,1.757,1.757,0,0,1,.506,1.356Z" transform="translate(-1162.39 -1616.974)" fill="#f7f7e8" />
                    </g>
                    <g id="Сгруппировать_136" data-name="Сгруппировать 136" transform="translate(1015.719 1352.763)">
                        <path id="Контур_228" data-name="Контур 228" d="M1020.857,1579.428v-6.075h-5.138v-2h7.652v8.075Zm-5.138,0v-4.353h2.514v4.353Z" transform="translate(-1015.719 -1571.353)" fill="#f7f7e8" />
                        <path id="Контур_229" data-name="Контур 229" d="M1046.5,1579.428v-2h2.043v-4.076h-1.807v-2h4.321v8.075Z" transform="translate(-1037.354 -1571.353)" fill="#f7f7e8" />
                        <path id="Контур_230" data-name="Контур 230" d="M1067.931,1575.829v-2.476H1066.6v-2h3.849v4.476Z" transform="translate(-1051.481 -1571.353)" fill="#f7f7e8" />
                        <path id="Контур_231" data-name="Контур 231" d="M1090.337,1573.353v6.075h-2.593v-6.075h-3.8v-2h7.667v2Z" transform="translate(-1063.674 -1571.353)" fill="#f7f7e8" />
                        <path id="Контур_232" data-name="Контур 232" d="M1117.977,1579.428v-2h1.76v-3.123c0-.662-.377-.954-1.021-.954h-1.367l-1.163,6.075h-2.514l1.273-6.075h-1.493v-2h5.656a2.849,2.849,0,0,1,3.143,3v5.076Z" transform="translate(-1084.417 -1571.353)" fill="#f7f7e8" />
                        <path id="Контур_233" data-name="Контур 233" d="M1154.552,1579.428v-6.075h-5.138v-2h7.652v8.075Zm-5.138,0v-4.353h2.514v4.353Z" transform="translate(-1109.695 -1571.353)" fill="#f7f7e8" />
                        <path id="Контур_234" data-name="Контур 234" d="M1192.869,1579.428v-5.122c0-.662-.377-.954-1.021-.954h-3.033v-2h3.425a2.849,2.849,0,0,1,3.142,3v5.076Z" transform="translate(-1137.39 -1571.353)" fill="#f7f7e8" />
                        <path id="Контур_235" data-name="Контур 235" d="M1222.778,1576a3.394,3.394,0,0,1-3.237,3.43h-3.331v-2h2.53a1.729,1.729,0,0,0,1.524-1.631v-.815a1.728,1.728,0,0,0-1.524-1.63h-2.53v-2h3.331a3.394,3.394,0,0,1,3.237,3.43Z" transform="translate(-1156.646 -1571.353)" fill="#f7f7e8" />
                        <path id="Контур_236" data-name="Контур 236" d="M1243.09,1575.829v-2.476h-1.335v-2h3.849v4.476Z" transform="translate(-1174.602 -1571.353)" fill="#f7f7e8" />
                        <path id="Контур_237" data-name="Контур 237" d="M1266.3,1576a3.394,3.394,0,0,1-3.237,3.43h-3.331v-2h2.529a1.728,1.728,0,0,0,1.524-1.631v-.815a1.728,1.728,0,0,0-1.524-1.63h-2.529v-2h3.331a3.394,3.394,0,0,1,3.237,3.43Z" transform="translate(-1187.241 -1571.353)" fill="#f7f7e8" />
                    </g>
                    <g id="Сгруппировать_138" data-name="Сгруппировать 138" transform="translate(1030.136 1260.376)">
                        <path id="Контур_238" data-name="Контур 238" d="M1149.811,1260.376c-.279,0-.556,0-.833.011v76.527c.277.006.554.011.833.011a38.274,38.274,0,0,0,0-76.548Z" transform="translate(-1123.806 -1260.376)" fill="#f7f7e8" />
                        <g id="Сгруппировать_137" data-name="Сгруппировать 137" transform="translate(0 14.351)">
                            <path id="Контур_239" data-name="Контур 239" d="M1116.59,1445.367l1.907.594-.182.586a1.2,1.2,0,0,0-.089.545.282.282,0,0,0,.209.188.325.325,0,0,0,.328-.064.921.921,0,0,0,.238-.429,1.364,1.364,0,0,0,.078-.629,1.619,1.619,0,0,0-.43-.663,6.15,6.15,0,0,1-1.312-1.874,3.207,3.207,0,0,1,.168-1.794,3.084,3.084,0,0,1,.584-1.182,1.623,1.623,0,0,1,.972-.433,3.163,3.163,0,0,1,1.4.139,3.085,3.085,0,0,1,1.311.75,1.6,1.6,0,0,1,.516,1.033,4.265,4.265,0,0,1-.246,1.427l-.161.517-1.907-.594.3-.961a1.334,1.334,0,0,0,.1-.6.327.327,0,0,0-.246-.216.351.351,0,0,0-.355.066.966.966,0,0,0-.249.447,1.555,1.555,0,0,0-.094.971,6.375,6.375,0,0,0,.739,1.016,9.839,9.839,0,0,1,.785,1.053,1.8,1.8,0,0,1,.215.763,2.923,2.923,0,0,1-.158,1.085,3.011,3.011,0,0,1-.645,1.255,1.633,1.633,0,0,1-.953.423,3.139,3.139,0,0,1-1.323-.152,3.414,3.414,0,0,1-1.283-.683,1.5,1.5,0,0,1-.544-.884,3.617,3.617,0,0,1,.232-1.391Z" transform="translate(-1100.801 -1401.115)" fill="#f7f7e8" />
                            <path id="Контур_240" data-name="Контур 240" d="M1088.348,1432.941l5.174-6.454,1.678,1.346-2.173,2.711.5.4,2.173-2.711,1.678,1.346-5.174,6.454-1.678-1.346,1.851-2.308-.5-.4-1.85,2.308Z" transform="translate(-1081.188 -1391.488)" fill="#f7f7e8" />
                            <path id="Контур_241" data-name="Контур 241" d="M1072.3,1413.359l6.568-5.177,1.092,1.908-1.233.839.382.668,1.355-.626,1.1,1.93-7.725,3.156Zm5.182-1.653q-1.139.84-2.853,2.011,2.226-.895,3.22-1.368Z" transform="translate(-1069.911 -1378.622)" fill="#f7f7e8" />
                            <path id="Контур_242" data-name="Контур 242" d="M1064.943,1394.914l-.23-1.505a11.682,11.682,0,0,1-.2-2.055,1.42,1.42,0,0,1,.461-.96,2.728,2.728,0,0,1,1.472-.568,2.4,2.4,0,0,1,1.32.042,1.26,1.26,0,0,1,.536.874,1.618,1.618,0,0,1,.29-.913,1.26,1.26,0,0,1,.441-.344,7.663,7.663,0,0,1,1.2-.238l2.157-.329.3,1.975-2.718.415a1.894,1.894,0,0,0-.8.228q-.14.127-.073.567l3.688-.564.324,2.127Zm1.074-2.34,1.818-.278a.95.95,0,0,0-.175-.488q-.12-.129-.661-.046l-.449.069a.69.69,0,0,0-.489.217A.8.8,0,0,0,1066.016,1392.574Z" transform="translate(-1064.434 -1365.08)" fill="#f7f7e8" />
                            <path id="Контур_243" data-name="Контур 243" d="M1064.248,1369.861l.7-3.517,1.623.325-.282,1.408,1.538.308.264-1.317,1.543.309-.264,1.318,1.784.358.31-1.548,1.623.325-.733,3.657Z" transform="translate(-1064.248 -1349.213)" fill="#f7f7e8" />
                            <path id="Контур_244" data-name="Контур 244" d="M1073.135,1340.667l1.438.819-.632,1.11,5.75,3.275-1.065,1.869-5.749-3.275-.63,1.105-1.438-.819Z" transform="translate(-1068.859 -1331.164)" fill="#f7f7e8" />
                            <path id="Контур_245" data-name="Контур 245" d="M1093.9,1324.745a1.28,1.28,0,0,1,.87-.049,3.808,3.808,0,0,1,1.258,1.151,4.139,4.139,0,0,1,.807,1.321,1.6,1.6,0,0,1-.067,1.133,3.09,3.09,0,0,1-.906,1.124,3.037,3.037,0,0,1-1.395.732,1.6,1.6,0,0,1-1.082-.149,5.371,5.371,0,0,1-1.2-1.161l-.419-.5,1.586-1.321.86,1.033a2.167,2.167,0,0,0,.486.483.275.275,0,0,0,.311-.07.309.309,0,0,0,.126-.344,3.452,3.452,0,0,0-.556-.787l-.367-.44a1.681,1.681,0,0,0-.527-.465.532.532,0,0,0-.428-.019,3.253,3.253,0,0,0-.675.459l-.768-.923a4.159,4.159,0,0,0,.663-.625.394.394,0,0,0,.035-.348,1.69,1.69,0,0,0-.32-.5l-.294-.353a1.2,1.2,0,0,0-.435-.383.309.309,0,0,0-.3.072.306.306,0,0,0-.133.3,1.561,1.561,0,0,0,.337.528l.435.522-1.586,1.321-.451-.542a1.93,1.93,0,0,1-.609-1.578,2.841,2.841,0,0,1,1.056-1.422,2.682,2.682,0,0,1,1.909-.838,2.291,2.291,0,0,1,1.43.9,2,2,0,0,1,.5.892A1.694,1.694,0,0,1,1093.9,1324.745Z" transform="translate(-1081.736 -1318.105)" fill="#f7f7e8" />
                            <path id="Контур_246" data-name="Контур 246" d="M1118.918,1311.973a1.491,1.491,0,0,1,.838.3,3.787,3.787,0,0,1,.782,1.574,4.168,4.168,0,0,1,.282,1.463,1.583,1.583,0,0,1-.471.978,3.184,3.184,0,0,1-1.334.764,3.061,3.061,0,0,1-1.474.183,1.74,1.74,0,0,1-.973-.49,3.956,3.956,0,0,1-.726-1.445,6.48,6.48,0,0,1-.3-1.273,1.314,1.314,0,0,1,.407-1.067,1.2,1.2,0,0,1-.73-.373,2.363,2.363,0,0,1-.5-.906,2.047,2.047,0,0,1,.057-1.706,2.574,2.574,0,0,1,1.514-1.066,2.5,2.5,0,0,1,1.968-.057,2.308,2.308,0,0,1,1.005,1.371,2.025,2.025,0,0,1,.142.97A1.774,1.774,0,0,1,1118.918,1311.973Zm-1.661-1.319a1.336,1.336,0,0,0-.245-.5.293.293,0,0,0-.312-.039.278.278,0,0,0-.216.225,1.39,1.39,0,0,0,.1.539l.2.6a1.147,1.147,0,0,0,.241.464.292.292,0,0,0,.307.048.279.279,0,0,0,.218-.22,1.522,1.522,0,0,0-.115-.573Zm1.125,3.25a1.352,1.352,0,0,0-.292-.568.334.334,0,0,0-.34-.051.314.314,0,0,0-.232.236,1.448,1.448,0,0,0,.116.632l.339,1.018a1.493,1.493,0,0,0,.312.623.347.347,0,0,0,.347.046.3.3,0,0,0,.232-.241,1.976,1.976,0,0,0-.149-.691Z" transform="translate(-1099.612 -1308.68)" fill="#f7f7e8" />
                        </g>
                        <path id="Контур_247" data-name="Контур 247" d="M1149.811,1319.812c-.28,0-.556.01-.833.021v41.191c.277.011.553.021.833.021a20.617,20.617,0,1,0,0-41.233Z" transform="translate(-1123.806 -1302.154)" fill="#21bf73" />
                        <path id="Контур_248" data-name="Контур 248" d="M1149.811,1304.717c-.279,0-.555.012-.833.021v8.969q.413-.021.833-.021a16.132,16.132,0,1,1,0,32.265q-.419,0-.833-.021v8.969c.277.009.553.021.833.021a25.1,25.1,0,1,0,0-50.2Z" transform="translate(-1123.806 -1291.544)" fill="#23242f" />
                    </g>
                </g>
            </svg>

        </div>
    );
};

export default TabThird;